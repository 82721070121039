<template>
  <!-- Sidebar -->
  <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

    <!-- Sidebar - Brand -->
    <li>
      <router-link :to="{ name: 'dashboard' }" class="sidebar-brand d-flex align-items-center justify-content-center">
        <div class="sidebar-brand-icon"><i class="fas fa-recycle"></i></div>
        <div class="sidebar-brand-text mx-3">ECONIK CRM</div>
      </router-link>
    </li>

    <!-- Divider -->
    <li><hr class="sidebar-divider my-0"></li>

    <!-- Nav Item - Dashboard -->
    <li :class="'nav-item ' + (active === 'dashboard' ? 'active' : '')">
      <router-link :to="{ name: 'dashboard' }" class="nav-link">
        <i class="fas fa-fw fa-home"></i>
        <span>Αρχική</span>
      </router-link>
    </li>

    <!-- Divider -->
    <li><hr class="sidebar-divider"></li>

    <!-- Heading -->
    <li class="sidebar-heading">ΓΕΝΙΚΑ</li>

    <!-- Nav Item - Customers Menu -->
    <li :class="'nav-item ' + (active === 'clients' ? 'active' : '')">
      <router-link :to="{ name: 'clients' }" class="nav-link">
        <i class="fas fa-fw fa-users"></i>
        <span>Πελάτες</span>
      </router-link>
    </li>

    <!-- Nav Item - Products Menu -->
    <li :class="'nav-item ' + (active === 'products' ? 'active' : '')">
      <router-link :to="{ name: 'products' }" class="nav-link">
        <i class="fas fa-fw fa-shopping-cart"></i>
        <span>Προϊόντα</span>
      </router-link>
    </li>

    <!-- Nav Item - Stock Menu -->
    <li :class="'nav-item ' + (active === 'stock' ? 'active' : '')">
      <router-link :to="{ name: 'stock' }" class="nav-link">
        <i class="fas fa-truck-loading"></i>
        <span>Αποθήκη</span>
      </router-link>
    </li>

    <!-- Divider -->
    <li><hr class="sidebar-divider"></li>

    <!-- Heading -->
    <li class="sidebar-heading">ΡΥΘΜΙΣΕΙΣ</li>

    <li :class="'nav-item ' + (active === 'invoices' ? 'active' : '')">
      <router-link :to="{ name: 'invoices' }" class="nav-link">
        <i class="fas fa-fw fa-file-invoice"></i>
        <span>Τιμολόγια</span>
      </router-link>
    </li>

    <li :class="'nav-item ' + (active === 'invoice-types' ? 'active' : '')">
      <router-link :to="{ name: 'invoice-types' }" class="nav-link">
        <i class="fas fa-fw fa-file-invoice-dollar"></i>
        <span>Είδη Παραστατικών</span>
      </router-link>
    </li>

    <!-- Nav Item - Countries -->
    <li :class="'nav-item ' + (active === 'countries' ? 'active' : '')">
      <router-link :to="{ name: 'countries' }" class="nav-link">
        <i class="fas fa-fw fa-globe"></i>
        <span>Χώρες</span>
      </router-link>
    </li>

    <li :class="'nav-item ' + (active === 'countries-vats' ? 'active' : '')">
      <router-link :to="{ name: 'countries-vats' }" class="nav-link">
        <i class="fas fa-fw fa-funnel-dollar"></i>
        <span>Φ.Π.Α. Χωρών</span>
      </router-link>
    </li>

    <li :class="'nav-item ' + (active === 'admins' ? 'active' : '')">
      <router-link :to="{ name: 'admins' }" class="nav-link">
        <i class="fas fa-fw fa-user-shield"></i>
        <span>Διαχειριστές</span>
      </router-link>
    </li>

    <!-- Divider -->
    <li><hr class="sidebar-divider"></li>

    <!-- Sidebar Toggler (Sidebar) -->
    <li class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </li>

  </ul>
  <!-- End of Sidebar -->
</template>

<script>
export default {
  name: "SidebarComponent",
  props: ['active']
}
</script>

<style scoped>

</style>
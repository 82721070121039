import $ from "jquery";
import { createToaster } from "@meforma/vue-toaster";

export default function catchCrudErrors (error) {
    const toaster = createToaster();
    const modal = $(".modal");

    switch (error.status) {
        case 422:
            toaster.error(error.data.errors[Object.keys(error.data.errors)[0]][0]);
            break;
        case 401:
            modal.modal("hide")
            toaster.error('Σφάλμα διαπιστευτηρίων! Αποσύνδεση...')
            localStorage.clear();
            location.href = '/login';
            break;
        case 429:
            modal.modal("hide")
            toaster.error('Υπερβολικά πολλά αιτήματα! Αποσύνδεση...')
            localStorage.clear();
            location.href = '/login';
            break;
        case 404:
            modal.modal("hide")
            if (error.data.message) {
                toaster.error(error.data.message);
            }
            break;
    }
}
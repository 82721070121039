<template>
  <!-- Logout Modal-->
    <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Θέλετε σίγουρα να αποσυνδεθείτε από το CRM;</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">Επιλέξτε "Αποσύνδεση" για άμεση έξοδο, ή "Ακύρωση" για επιστροφή στο CRM</div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Ακύρωση</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal" @click="logout">Αποσύνδεση
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "LogoutModalComponent",
    data() {
        return {
            intervalId: null,
            lastActive: Date.now(),
        }
    },
    mounted() {
        this.intervalId = setInterval(() => {
            if (Date.now() - this.lastActive > 1200000) { // 20 Minutes
                this.$toast.error('Η συνεδρία έλληξε! Αποσύνδεση...')
                clearInterval(this.intervalId);
                this.logout();
            }
        }, 1000);

        // Zero the idle timer on mouse movement or on any key pressed.
        document.addEventListener('mousemove', this.updateLastActive);
        document.addEventListener('keypress', this.updateLastActive);
    },
    methods: {
        logout() {
            // Ajax Request to the REST API
            clearInterval(this.intervalId);
            axios.post(`${process.env.VUE_APP_API_URL}/api/logout`, {}, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
                this.$toast.success(response.data.message);
                location.href = `${process.env.VUE_APP_URL}/login`;
            }).catch(error => {
                if (error.response.status === 401) {
                    this.$toast.error('Σφάλμα διαπιστευτηρίων! Αποσύνδεση...')
                    location.href = `${process.env.VUE_APP_URL}/login`
                } else if (error.response.status === 429) {
                    this.$toast.error('Υπερβολικά πολλά αιτήματα! Αποσύνδεση...')
                    location.href = `${process.env.VUE_APP_URL}/login`
                }
            })

        },
        updateLastActive() {
            this.lastActive = Date.now();
        },
    }
}
</script>

<style scoped>

</style>
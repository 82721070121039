<template>
  <div class="container">
    <div class="d-flex justify-content-center mt-5">
      <img src="@/assets/img/logo.png" alt="econik group" class="img-fluid">
    </div>
    <div class="card card-login mx-auto mt-5">
      <div class="card-header">{{ title }}</div>
      <div class="card-body">
        <slot/>
      </div>
    </div>

  </div>
</template>

<script>
import "jquery/dist/jquery.min"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "@/assets/vendor/jquery-easing/jquery.easing.min.js"

export default {
  name: "AuthLayout",
  props: ['title'],
  mounted() {
    document.body.classList.add('bg-dark')
  }
}
</script>

<style scoped>
@import "@/assets/vendor/fontawesome-free/css/all.min.css";
@import "@/assets/css/google.css";
@import "@/assets/css/sb-admin-2.css";
</style>
<template>
  <!-- Scroll to Top Button-->
  <a class="scroll-to-top rounded" href="#page-top">
    <i class="fas fa-angle-up"></i>
  </a>
</template>

<script>
export default {
  name: "ScrollToTopComponent"
}
</script>

<style scoped>

</style>
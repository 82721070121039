<template>
    <admin-layout :active-menu="page.active" :page-title="page.title">
        <!-- Content Row -->
        <div class="row">
            <!-- Earnings (Monthly) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-primary shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">ΠΕΛΑΤΕΣ</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ counters.clients }}</div>
                            </div>
                            <div class="col-auto"><i class="fas fa-users fa-2x text-gray-300"></i></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-success shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-success text-uppercase mb-1">ΣΥΝΟΛΙΚΟΣ ΤΖΙΡΟΣ</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ counters.turnover }} €</div>
                            </div>
                            <div class="col-auto"><i class="fas fa-euro-sign fa-2x text-gray-300"></i></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-info shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-info text-uppercase mb-1">ΣΥΝΟΛΙΚΑ ΠΡΟΪΟΝΤΑ</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ counters.products }}</div>
                            </div>
                            <div class="col-auto"><i class="fas fa-shopping-cart fa-2x text-gray-300"></i></div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pending Requests Card Example -->
            <div class="col-xl-3 col-md-6 mb-4">
                <div class="card border-left-warning shadow h-100 py-2">
                    <div class="card-body">
                        <div class="row no-gutters align-items-center">
                            <div class="col mr-2">
                                <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">ΣΥΝΟΛΙΚΑ ΠΑΡΑΣΤΑΤΙΚΑ</div>
                                <div class="h5 mb-0 font-weight-bold text-gray-800">{{ counters.invoices }}</div>
                            </div>
                            <div class="col-auto"><i class="fas fa-file-invoice fa-2x text-gray-300"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content Row -->

        <div class="row">

            <!-- Area Chart -->
            <div class="col-xl-8 col-lg-7">
                <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Προεπισκόπιση Εσόδων</h6>
                        <div class="dropdown no-arrow">
                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                 aria-labelledby="dropdownMenuLink">
                                <div class="dropdown-header"><i class="fas fa-cog mr-2"></i>ΕΠΙΛΟΓΕΣ:</div>
                                <a class="dropdown-item" href="#"><i class="fas fa-calendar-alt mr-2"></i>Προβολή ανά
                                    μήνα</a>
                                <a class="dropdown-item" href="#"><i class="fas fa-calendar-alt mr-2"></i>Προβολή ανά
                                    έτος</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#"><i class="fas fa-print mr-2"></i>Εκτύπωση αναφοράς</a>
                            </div>
                        </div>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                        <div class="chart-area">
                            <canvas id="myAreaChart"></canvas>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Pie Chart -->
            <div class="col-xl-4 col-lg-5">
                <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">Προεπισκόπιση Προϊόντων</h6>
                        <div class="dropdown no-arrow">
                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in" aria-labelledby="dropdownMenuLink">
                                <a class="dropdown-item" href="javascript:void(0);"><i class="fas fa-print mr-2"></i>Εκτύπωση αναφοράς</a>
                            </div>
                        </div>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                        <div class="chart-pie pt-4 pb-2">
                            <canvas id="products-pie-chart"></canvas>
                        </div>
                        <div class="mt-4 text-center small">
                            <span class="mr-2"><i class="fas fa-circle text-primary"></i> Απλά Προϊόντα</span>
                            <span class="mr-2"><i class="fas fa-circle text-success"></i> Πακέτα Προϊόντων</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<!--        &lt;!&ndash; Content Row &ndash;&gt;-->
<!--        <div class="row">-->

<!--            &lt;!&ndash; Content Column &ndash;&gt;-->
<!--            <div class="col-lg-6 mb-4">-->

<!--                &lt;!&ndash; Project Card Example &ndash;&gt;-->
<!--                <div class="card shadow mb-4">-->
<!--                    <div class="card-header py-3">-->
<!--                        <h6 class="m-0 font-weight-bold text-primary">Προεπισκόπιση Έργων</h6>-->
<!--                    </div>-->
<!--                    <div class="card-body">-->
<!--                        <h4 class="small font-weight-bold">Τιμολόγια <span-->
<!--                                class="float-right">20%</span></h4>-->
<!--                        <div class="progress mb-4">-->
<!--                            <div class="progress-bar bg-danger" role="progressbar" style="width: 20%"-->
<!--                                 aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                        </div>-->
<!--                        <h4 class="small font-weight-bold">Παρακολούθηση Πωλήσεων <span-->
<!--                                class="float-right">40%</span></h4>-->
<!--                        <div class="progress mb-4">-->
<!--                            <div class="progress-bar bg-warning" role="progressbar" style="width: 40%"-->
<!--                                 aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                        </div>-->
<!--                        <h4 class="small font-weight-bold">Πελατολόγιο <span-->
<!--                                class="float-right">60%</span></h4>-->
<!--                        <div class="progress mb-4">-->
<!--                            <div class="progress-bar" role="progressbar" style="width: 60%"-->
<!--                                 aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                        </div>-->
<!--                        <h4 class="small font-weight-bold">Πληρωμές <span-->
<!--                                class="float-right">80%</span></h4>-->
<!--                        <div class="progress mb-4">-->
<!--                            <div class="progress-bar bg-info" role="progressbar" style="width: 80%"-->
<!--                                 aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                        </div>-->
<!--                        <h4 class="small font-weight-bold">Λογιστήριο <span-->
<!--                                class="float-right">Complete!</span></h4>-->
<!--                        <div class="progress">-->
<!--                            <div class="progress-bar bg-success" role="progressbar" style="width: 100%"-->
<!--                                 aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--                &lt;!&ndash; Color System &ndash;&gt;-->
<!--                <div class="row">-->
<!--                    <div class="col-lg-6 mb-4">-->
<!--                        <div class="card bg-primary text-white shadow">-->
<!--                            <div class="card-body">-->
<!--                                Primary-->
<!--                                <div class="text-white-50 small">#4e73df</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-6 mb-4">-->
<!--                        <div class="card bg-success text-white shadow">-->
<!--                            <div class="card-body">-->
<!--                                Success-->
<!--                                <div class="text-white-50 small">#1cc88a</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-6 mb-4">-->
<!--                        <div class="card bg-info text-white shadow">-->
<!--                            <div class="card-body">-->
<!--                                Info-->
<!--                                <div class="text-white-50 small">#36b9cc</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-6 mb-4">-->
<!--                        <div class="card bg-warning text-white shadow">-->
<!--                            <div class="card-body">-->
<!--                                Warning-->
<!--                                <div class="text-white-50 small">#f6c23e</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-6 mb-4">-->
<!--                        <div class="card bg-danger text-white shadow">-->
<!--                            <div class="card-body">-->
<!--                                Danger-->
<!--                                <div class="text-white-50 small">#e74a3b</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-6 mb-4">-->
<!--                        <div class="card bg-secondary text-white shadow">-->
<!--                            <div class="card-body">-->
<!--                                Secondary-->
<!--                                <div class="text-white-50 small">#858796</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-6 mb-4">-->
<!--                        <div class="card bg-light text-black shadow">-->
<!--                            <div class="card-body">-->
<!--                                Light-->
<!--                                <div class="text-black-50 small">#f8f9fc</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-6 mb-4">-->
<!--                        <div class="card bg-dark text-white shadow">-->
<!--                            <div class="card-body">-->
<!--                                Dark-->
<!--                                <div class="text-white-50 small">#5a5c69</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->

<!--            <div class="col-lg-6 mb-4">-->

<!--                &lt;!&ndash; Illustrations &ndash;&gt;-->
<!--                <div class="card shadow mb-4">-->
<!--                    <div class="card-header py-3">-->
<!--                        <h6 class="m-0 font-weight-bold text-primary">Illustrations</h6>-->
<!--                    </div>-->
<!--                    <div class="card-body">-->
<!--                        <div class="text-center">-->
<!--                            <img class="img-fluid px-3 px-sm-4 mt-3 mb-4" style="width: 25rem;"-->
<!--                                 src="@/assets/img/undraw_posting_photo.svg" alt="...">-->
<!--                        </div>-->
<!--                        <p>Add some quality, svg illustrations to your project courtesy of <a-->
<!--                                target="_blank" rel="nofollow" href="https://undraw.co/">unDraw</a>, a-->
<!--                            constantly updated collection of beautiful svg images that you can use-->
<!--                            completely free and without attribution!</p>-->
<!--                        <a target="_blank" rel="nofollow" href="https://undraw.co/">Browse Illustrations on-->
<!--                            unDraw &rarr;</a>-->
<!--                    </div>-->
<!--                </div>-->

<!--                &lt;!&ndash; Approach &ndash;&gt;-->
<!--                <div class="card shadow mb-4">-->
<!--                    <div class="card-header py-3">-->
<!--                        <h6 class="m-0 font-weight-bold text-primary">Development Approach</h6>-->
<!--                    </div>-->
<!--                    <div class="card-body">-->
<!--                        <p>SB Admin 2 makes extensive use of Bootstrap 4 utility classes in order to reduce-->
<!--                            CSS bloat and poor page performance. Custom CSS classes are used to create-->
<!--                            custom components and custom utility classes.</p>-->
<!--                        <p class="mb-0">Before working with this theme, you should become familiar with the-->
<!--                            Bootstrap framework, especially the utility classes.</p>-->
<!--                    </div>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->

    </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";

import Chart from "chart.js";
import guard from "@/library/check-token";

import axios from "axios";

export default {
    name: "DashboardView",
    components: {AdminLayout},
    beforeRouteEnter(to, from, next) {
        guard(to, from, next)
    },
    data() {
        return {
            page: {
                active: "dashboard",
                title: '<i class="fas fa-fw fa-home mr-2"></i>Αρχική'
            },
            counters: {
                clients: 0,
                turnover: 0,
                products: 0,
                invoices: 0
            },
            products: {
                single: 0,
                bundles: 0,
            },
            productsPieChart: null,
            areaChart: null
        }
    },
    mounted() {

        axios.get(`${process.env.VUE_APP_API_URL}/api/counters`, {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
        }).then(
            response => this.counters = response.data
        )

        axios.get(`${process.env.VUE_APP_API_URL}/api/products/overview`, {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
        }).then((response) => {
            this.products = response.data

            this.productsPieChart = new Chart(document.getElementById('products-pie-chart'), {
                type: 'doughnut',
                data: {
                    labels: ["Απλά Προϊόντα", "Πακέτα Προϊόντων"],
                    datasets: [{
                        data: [parseInt(this.products.single), parseInt(this.products.bundles)],
                        backgroundColor: ['#4e73df', '#1cc88a', '#36b9cc'],
                        hoverBackgroundColor: ['#2e59d9', '#17a673', '#2c9faf'],
                        hoverBorderColor: "rgba(234, 236, 244, 1)",
                    }],
                },
                options: {
                    maintainAspectRatio: false,
                    tooltips: {
                        backgroundColor: "rgb(255,255,255)",
                        bodyFontColor: "#858796",
                        borderColor: '#dddfeb',
                        borderWidth: 1,
                        xPadding: 15,
                        yPadding: 15,
                        displayColors: false,
                        caretPadding: 10,
                    },
                    legend: {
                        display: false
                    },
                    cutoutPercentage: 80,
                },
            });

        })

        this.areaChart = new Chart(document.getElementById('myAreaChart'), {
            type: 'line',
            data: {
                labels: ["Ιαν", "Φεβ", "Μαρ", "Απρ", "Μαϊ", "Ιουν", "Ιουλ", "Αυγ", "Σεπ", "Οκτ", "Νοε", "Δεκ"],
                datasets: [{
                    label: "Earnings",
                    lineTension: 0.3,
                    backgroundColor: "rgba(78, 115, 223, 0.05)",
                    borderColor: "rgba(78, 115, 223, 1)",
                    pointRadius: 3,
                    pointBackgroundColor: "rgba(78, 115, 223, 1)",
                    pointBorderColor: "rgba(78, 115, 223, 1)",
                    pointHoverRadius: 3,
                    pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
                    pointHoverBorderColor: "rgba(78, 115, 223, 1)",
                    pointHitRadius: 10,
                    pointBorderWidth: 2,
                    data: [0, 10000, 5000, 15000, 10000, 20000, 15000, 25000, 20000, 30000, 25000, 40000],
                }],
            },
            options: {
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 25,
                        top: 25,
                        bottom: 0
                    }
                },
                scales: {
                    xAxes: [{
                        time: {
                            unit: 'date'
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            maxTicksLimit: 7
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            maxTicksLimit: 5,
                            padding: 10,
                            // Include a dollar sign in the ticks
                            // eslint-disable-next-line no-unused-vars
                            callback: (value, index, values) => {
                                return this.numberFormat(value) + " €";
                            }
                        },
                        gridLines: {
                            color: "rgb(234, 236, 244)",
                            zeroLineColor: "rgb(234, 236, 244)",
                            drawBorder: false,
                            borderDash: [2],
                            zeroLineBorderDash: [2]
                        }
                    }],
                },
                legend: {
                    display: false
                },
                tooltips: {
                    backgroundColor: "rgb(255,255,255)",
                    bodyFontColor: "#858796",
                    titleMarginBottom: 10,
                    titleFontColor: '#6e707e',
                    titleFontSize: 14,
                    borderColor: '#dddfeb',
                    borderWidth: 1,
                    xPadding: 15,
                    yPadding: 15,
                    displayColors: false,
                    intersect: false,
                    mode: 'index',
                    caretPadding: 10,
                    callbacks: {
                        label: (tooltipItem, chart) => {
                            var datasetLabel = chart.datasets[tooltipItem.datasetIndex].label || '';
                            return datasetLabel + ': $' + this.numberFormat(tooltipItem.yLabel);
                        }
                    }
                }
            }
        });
    },
    methods: {
        numberFormat(number, decimals, dec_point, thousands_sep) {
            // *     example: number_format(1234.56, 2, ',', ' ');
            // *     return: '1 234,56'
            number = (number + '').replace(',', '').replace(' ', '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? '.' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? ',' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        }
    }

}
</script>

<style scoped>

</style>
<template>
    <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-hidden="true">
        <div :class="'modal-dialog ' + size" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-html="title"></h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot/>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal"><i class="fas fa-ban mr-2"></i>Κλείσιμο / Ακύρωση</button>
                    <button class="btn btn-primary" type="button" v-if="button" @click="action" v-html="button"></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalComponent",
    props: {
        'id': {type: String},
        'title': {type: String},
        'button': {type: String},
        'action': {type: Function},
        'size': {type: String, default: 'modal-lg'}
    }
}
</script>

<style scoped>

</style>
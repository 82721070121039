<template>
    <admin-layout :active-menu="page.active" :page-title="page.title" :page-button="page.button">

        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Πίνακας Διαχείρισης Παραστατικών</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="invoices-datatable">
                        <thead>
                        <tr>
                            <th><i class="fas fa-calendar-alt mr-2"></i>Ημερομηνία</th>
                            <th><i class="fas fa-file-invoice-dollar mr-2"></i>Τύπος</th>
                            <th><i class="fas fa-hashtag mr-2"></i>Αριθμός</th>
                            <th><i class="fas fa-user mr-2"></i>Πελάτης</th>
                            <th><i class="fas fa-euro-sign mr-2"></i>Σύνολο</th>
                            <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
                        </tr>
                        </thead>
                        <tfoot>
                        <tr>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
                        </tr>
                        </tfoot>
                        <tbody>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <modal-component id="invoice-modal" :title="modal.title" :button="modal.button" :action="modal.action" :size="'modal-xl'">
            <InvoiceComponent :invoice="invoice"/>
        </modal-component>

    </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import $ from "jquery";
import datatableLanguages from "@/library/DatatableLanguages";
import catchDatatableErrors from "@/library/error-handling/Datatable";
import catchCrudErrors from "@/library/ErrorHandling";
import {destroyElement} from "@/library/CRUD";
import axios from "axios";
import ModalComponent from "@/components/ModalComponent.vue";
import InvoiceComponent from "@/components/InvoiceComponent.vue";

export default {
    name: "InvoicesView",
    components: {InvoiceComponent, ModalComponent, AdminLayout},
    data() {
        return {
            modal: {
                title: '<i class="fas fa-invoice mr-2"></i> Προεπισκόπιση Παραστατικού',
                button: '<i class="fas fa-print mr-2"></i> Εκτύπωση σε PDF',
                action: this.download(),
            },
            page: {
                active: "invoices",
                title: '<i class="fas fa-fw fa-file-invoice mr-2"></i>Τιμολόγια',
                button: `<button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onclick="create()"><i class="fas fa-plus fa-sm text-white-50"></i> Δημιουργία Παραστατικού</button>`
            },
            invoice: {
                number: '',
                date: '',
                client: {},
                products: {},
                subTotal: '',
                vatTotal: '',
                total: '',
            }
        }
    },
    created() {
        window.create = this.create;
        window.view = this.view;
        window.download = this.download;
        window.destroy = this.destroy;
    },
    mounted() {
        this.table = $("#invoices-datatable").DataTable({
            initComplete: function () {
                this.api()
                    .columns()
                    .every(function () {
                        const that = this;

                        $('input', this.footer()).on('keyup change clear', function () {
                            if (that.search() !== this.value) {
                                that.search(this.value).draw();
                            }
                        });
                    });
            },
            columnDefs: [
                {
                    data: 'created_date',
                    name: 'created_date',
                    orderable: true,
                    className: 'dt-center',
                    targets: 0,
                    width: 'auto'
                },
                {
                    data: null,
                    name: 'type_description',
                    render: (data) => this.getTypeSpan(data),
                    orderable: true,
                    className: 'dt-center',
                    targets: 1,
                    width: 'auto'
                },
                {data: 'num', name: 'num', orderable: true, className: 'dt-center', targets: 2, width: 'auto'},
                {data: 'client', name: 'client', orderable: true, className: 'dt-center', targets: 3, width: 'auto'},
                {
                    data: 'gen_total',
                    name: 'gen_total',
                    orderable: true,
                    className: 'dt-center',
                    targets: 4,
                    width: 'auto'
                },
                {
                    data: null,
                    render: (data) => this.getActionButtons(data),
                    orderable: false,
                    searchable: false,
                    className: 'dt-center',
                    targets: [5],
                    width: 'auto'
                }
            ],
            order: [[0, 'desc']],
            language: datatableLanguages,
            autoWidth: false,
            processing: true,
            serverSide: true,
            ajax: {
                type: 'POST',
                url: `${process.env.VUE_APP_API_URL}/api/invoices/data`,
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                error: (error) => catchDatatableErrors(error)
            },
        })
    },
    methods: {
        view(id) {
            axios({
                url: `${process.env.VUE_APP_API_URL}/api/invoices/view`,
                method: 'POST',
                responseType: 'json',
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                data: {id: id},
            }).then((response) => {
                this.invoice = response.data;
                $('#invoice-modal').modal('show');
            }).catch((error) => catchCrudErrors(error));
        },
        download(id, filename) {
            axios({
                url: `${process.env.VUE_APP_API_URL}/api/invoices/download`,
                method: 'POST',
                responseType: 'blob',
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                data: {id: id},
            }).then((response) => {
                // Create a new Blob object with the response data
                const blob = new Blob([response.data], {type: 'application/pdf'});

                // Create a new URL object
                const url = URL.createObjectURL(blob);

                // Create a new anchor tag with the URL object's href
                const link = document.createElement('a');
                link.href = url;

                // Set the download attribute to the desired filename
                link.download = filename;

                // Click the anchor tag to initiate the download
                link.click();

                // Release the resources used by the Blob object
                URL.revokeObjectURL(url);

            }).catch((error) => catchCrudErrors(error));
        },
        destroy(id) {
            destroyElement(id, `${process.env.VUE_APP_API_URL}/api/invoices/destroy`, 'Αυτή η ενέργεια θα διαγράψει μόνιμα το παραστατικό!', this.table);
        },
        create() {
            this.$router.push({name: "invoice-create"});
        },
        getActionButtons(data) {
            let viewButton = `<button class="btn btn-outline-secondary btn-sm mr-1" id="view-invoice-${data.id}" onclick="view(${data.id}, '${data.invoice_file_name}')"><i class="fas fa-eye"></i></button>`;
            let downloadButton = `<button class="btn btn-outline-primary btn-sm mx-1" id="download-invoice-${data.id}" onclick="download(${data.id}, '${data.invoice_file_name}')"><i class="fas fa-download"></i></button>`;
            let deleteButton = `<button class="btn btn-outline-danger btn-sm ml-1" id="delete-invoice-${data.id}" onclick="destroy(${data.id})"><i class="fas fa-trash"></i></button>`;
            return viewButton + downloadButton + deleteButton;
        },
        getTypeSpan(data) {
            return `<span class="badge badge-pill badge-${data.type === 1 ? 'success' : 'secondary'} ml-2">${data.type_description}</span>`;
        }
    }
}
</script>

<style scoped>
@import '@/assets/css/invoice.css';
</style>
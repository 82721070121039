<template>
  <!-- Topbar -->
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

    <!-- Sidebar Toggle -->
    <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
      <i class="fa fa-bars"></i>
    </button>

    <!-- Login Info -->
    <div class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
      <i class="fas fa-info-circle fa-fw mr-2"></i>
      <span class="font-weight-bold">Προηγούμενη σύνδεση: </span>
      <span v-if="loginDate !== ''">{{ loginDate }} από την IP: {{ ip }}</span>
      <span v-else> Ποτέ</span>
      <span class="font-weight-bold"><i class="fas fa-clock ml-2 mr-2"></i>{{ time }}</span>
    </div>

    <!-- Navbar -->
    <ul class="navbar-nav ml-auto">

      <!-- Nav Item - Search Dropdown (Visible Only XS) -->
      <li class="nav-item dropdown no-arrow d-sm-none">
        <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-info-circle fa-fw"></i>
        </a>
        <!-- Dropdown - Messages -->
        <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
             aria-labelledby="searchDropdown">
          <div class="form-inline mr-auto w-100 navbar-search">
            <span class="font-weight-bold">Προηγούμενη σύνδεση: </span>
            <span v-if="loginDate !== ''">{{ loginDate }} από την IP: {{ ip }}</span>
            <span v-else> Ποτέ</span>
          </div>
        </div>
      </li>

      <!-- Nav Item - Alerts -->
      <li class="nav-item dropdown no-arrow mx-1">
        <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-bell fa-fw"></i>
          <!-- Counter - Alerts -->
          <span v-if="alerts.length > 0" class="badge badge-danger badge-counter">{{ alerts.length }}</span>
        </a>
        <!-- Dropdown - Alerts -->
        <div class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
             aria-labelledby="alertsDropdown">
          <h6 class="dropdown-header"><i class="fas fa-bell mr-2"></i>ΕΙΔΟΠΟΙΗΣΕΙΣ</h6>
          <div class="overflow-auto scrollable-alerts" v-if="alerts.length > 0">
            <a class="dropdown-item d-flex align-items-center" href="javascript:void(0)" v-for="alert in alerts" :key="alert.id" @click="viewClient(alert.type, alert.wc_data)" title="Προβολή Πελάτη">
              <div class="mr-3">
                <div v-if="alert.type === alertTypes.newOrder" class="icon-circle bg-primary"><i class="fas fa-shopping-bag text-white"></i></div>
              </div>
              <div>
                <div class="small text-gray-500">{{ alert.date }}</div>
                <span class="font-weight-bold">{{ alert.text }}</span>
                <div class="d-flex justify-content-between">
                  <a class="small" href="javascript:void(0)" @click="viewClient(alert.type, alert.wc_data)"><i class="fas fa-info-circle mr-1"></i>Προβολή Πελάτη</a>
                </div>
              </div>
            </a>
          </div>
          <div v-else>
            <a class="dropdown-item d-flex justify-content-center" href="javascript:void(0)">
              <span>Δεν υπάρχουν ειδοποιήσεις!</span>
            </a>
          </div>
          <div v-if="alerts.length > 0" class="dropdown-item text-center small text-gray-500">
<!--            <a href="javascript:void(0)"><i class="fas fa-check-circle mr-1"></i>Σημείωση όλων ως "αναγνωσμένα"</a>-->
          </div>
        </div>
      </li>

      <li class="topbar-divider d-none d-sm-block"></li>

      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">
          <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ username }}</span>
          <img class="img-profile rounded-circle" src="@/assets/img/profile/male.svg" alt="...">
        </a>
        <!-- Dropdown - User Information -->
        <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
          <button type="button" class="dropdown-item" @click="showProfile"><i
              class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>Ρυθμίσεις Προφίλ
          </button>
          <router-link :to="{name: 'activity-log'}" type="button" class="dropdown-item">
            <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>Ιστορικό Ενεργειών
          </router-link>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item" @click="prepareLogout">
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>Αποσύνδεση
          </button>
        </div>
      </li>

    </ul>

  </nav>
  <!-- End of Topbar -->

  <!-- Profile Modal-->
  <modal-component id="profile-modal" :title="modal.title" :button="modal.button" :action="modal.action">
    <form id="profile-form" autocomplete="off">
      <div class="row">
        <div class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-comments"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Όνομα" id="input-name" name="input-name"
                   v-model="inputs.name">
          </div>
        </div>
        <div class="col-6">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-comments"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Επώνυμο" id="input-surname"
                   name="input-surname" v-model="inputs.surname">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-at"></i></span>
            </div>
            <input type="email" class="form-control" placeholder="Email" id="input-email" name="input-email"
                   v-model="inputs.email" autocomplete="off" readonly>
          </div>
        </div>
      </div>
    </form>
  </modal-component>

  <!-- Clients Modal-->
  <div class="modal fade" id="clients-modal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title"><i class="fas fa-user mr-2"></i> Προσθήκη Νέου Πελάτη</h5>
          <button class="close" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <form id="clients-form">
            <div class="row">
              <div class="col-4">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-hashtag"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Κωδικός Πελάτη" id="input-code" name="input-code"
                         v-model="inputs.code">
                </div>
              </div>
              <div class="col-4">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-comments"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Όνομα Πελάτη" id="input-name" name="input-name"
                         v-model="inputs.name">
                </div>

              </div>
              <div class="col-4">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-comments"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Επώνυμο Πελάτη" id="input-surname" name="input-surname"
                         v-model="inputs.surname">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-at"></i></span>
                  </div>
                  <input type="email" class="form-control" placeholder="Email Πελάτη" id="input-email" name="input-email"
                         v-model="inputs.email">
                </div>
              </div>
              <div class="col-6">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-shopping-bag"></i></span>
                  </div>
                  <select class="form-control" id="input-type" name="input-type" v-model="inputs.type">
                    <option value="0" selected disabled>Τύπος</option>
                    <option value="1">Λιανική</option>
                    <option value="2">Χονδρική</option>
                  </select>
                </div>
              </div>
            </div>
            <hr>

            <div class="row">

              <div class="col-4">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-phone"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Τηλέφωνο" id="input-phone" name="input-phone" v-model="inputs.phone">
                </div>
              </div>

              <div class="col-4">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-map-marked"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Διεύθυνση" id="input-address" name="input-address" v-model="inputs.address">
                </div>
              </div>

              <div class="col-4">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-map-pin"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Ταχ. Κώδικας" id="input-zip" name="input-zip" v-model="inputs.zip">
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-4">

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-map"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Περιοχή" id="input-area" name="input-area"
                         v-model="inputs.area">
                </div>

              </div>
              <div class="col-4">

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-city"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Πόλη" id="input-city" name="input-city"
                         v-model="inputs.city">
                </div>

              </div>
              <div class="col-4">

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-globe"></i></span>
                  </div>
                  <select class="form-control" id="input-country" name="input-country" v-model="inputs.country">
                    <option value="0" selected disabled>Χώρα</option>
                    <option v-for="country in countries" :key="country.id" :value="country.id" selected>
                      {{ country.name }}
                    </option>
                  </select>
                </div>

              </div>
            </div>
            <hr>

            <div class="row">
              <div class="col-6">
                <div class="form-group">

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-briefcase"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Επάγγελμα" id="input-job" name="input-job"
                           v-model="inputs.job">
                  </div>

                </div>
              </div>
              <div class="col-6">

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-building"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Εταιρεία" id="input-company" name="input-company"
                         v-model="inputs.company">
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-6">

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-file-alt"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="ΑΦΜ" id="input-afm" name="input-afm"
                         v-model="inputs.afm">
                </div>

              </div>
              <div class="col-6">

                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-business-time"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="ΔΟΥ" id="input-doy" name="input-doy"
                         v-model="inputs.doy">
                </div>

              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal"><i class="fas fa-ban mr-2"></i> Ακύρωση</button>
          <button class="btn btn-danger" type="button" @click="deleteAlert(wc_data)"><i class="fas fa-times mr-2"></i> Αγνόηση</button>
          <button class="btn btn-primary" type="button" @click="storeClient()"><i class="fas fa-save mr-2"></i> Αποθήκευση</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import $ from "jquery";
import ModalComponent from "@/components/ModalComponent";
import catchCrudErrors from "@/library/ErrorHandling";
import Pusher from "pusher-js";
import {storeElement, updateElement} from "@/library/CRUD";
import axios from "axios";

export default {
  name: "TopbarComponent",
  components: {ModalComponent},
  created() {
    // update the time every second
    this.interval = setInterval(() => {
      // Concise way to format time according to system locale.
      // In my case this returns "3:48:00 am"
      this.time = Intl.DateTimeFormat('el-GR', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false
      }).format()
    }, 1000)
  },
  data() {
    let pusher = new Pusher('855b087afc4f5f1d9087', {
      cluster: 'eu',
      authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
      auth: {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
      }
    });
    return {
      channel: pusher.subscribe('private-client'),
      interval: null,
      time: null,

      idleTime: 0,
      idleInterval: null,

      alerts: [],
      alertTypes: {
        newOrder: 1,
      },
      countries: [],

      ip: localStorage.getItem('ip'),
      loginDate: localStorage.getItem('loginDate'),
      loginTime: localStorage.getItem('loginTime'),
      username: localStorage.getItem('username'),
      modal: {
        title: '<i class="fas fa-user mr-2"></i> Διόρθωση Στοιχείων Προφίλ',
        button: '<i class="fas fa-sync mr-2"></i> Ενημέρωση',
        action: null
      },
      clientsModal: {
        title: '',
        button: '',
        action: this.createClientFromWooCommerce,
      },
      form: '#profile-form',
      wc_data: null,
      inputs: {
        code: '',
        name: '',
        surname: '',
        email: '',
        type: 0,

        phone: '',
        address: '',
        area: '',
        city: '',
        country: 0,
        zip: '',

        job: '',
        company: '',
        afm: '',
        doy: ''
      },
    }
  },
  mounted() {
    this.getAlerts();
    this.channel.bind('client-registered', () => {
      this.getAlerts();
    });
    
    axios.get(`${process.env.VUE_APP_API_URL}/api/countries/getActive`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
    }).then(
        response => this.countries = response.data
    ).catch(
        error => catchCrudErrors(error.response)
    );

  },
  methods: {
    prepareLogout() {
      $("#logoutModal").modal('show');
    },
    showProfile() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/profile/show`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then(response => {
        this.inputs = response.data
        this.modal.action = this.updateProfile;
        $("#profile-modal").modal("show");
      }).catch(
          error => catchCrudErrors(error.response)
      )
    },
    updateProfile() {
      // Serialize the form
      $(this.form).serialize();
      let form = $(this.form);
      let formData = new FormData(form[0])

      const successHandler = (response) => {
        this.username = this.inputs.name + " " + this.inputs.surname;
        localStorage.setItem('username', this.inputs.name + " " + this.inputs.surname)
        this.$toast.success(response.data.message)
        $("#profile-modal").modal('hide');
      }

      updateElement(formData, `${process.env.VUE_APP_API_URL}/api/profile/update`, false, false, successHandler)
    },
    viewClient(type, wcData) {
      localStorage.setItem('loaderVisible', 'true');
      this.$parent.showLoader = true;
      let route = `${process.env.VUE_APP_API_URL}/api`;

      if (type === this.alertTypes.newOrder) {
        route += '/clients/getFromEshop';
      }

      axios.post(route, {wc_data: wcData}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then(response => {
        this.inputs = response.data;
        this.wc_data = wcData;
        $('#clients-modal').modal('show');
      }).catch(error => {
        if (error.response.status === 400 || (error.response.status === 422 && this.inputs.email.length > 0 && this.validateEmail(this.inputs.email))) {
          this.deleteAlert(wcData);
          this.$toast.success('Ο Πελάτης υπάρχει ήδη!');
        }
        this.getAlerts();
      }).finally(() => {
        this.$parent.showLoader = false;
      });

    },
    storeClient() {
      // Serialize the form
      $(this.form).serialize();
      let form = $('#clients-form');
      let formData = new FormData(form[0])

      storeElement(formData, `${process.env.VUE_APP_API_URL}/api/clients/store`, null, $("#clients-modal"))
    },
    getAlerts() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/alerts/data`, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then(response => {
        this.alerts = response.data;
      }).catch(
          error => catchCrudErrors(error.response)
      );
    },
    deleteAlert(wc_data) {
      axios({
        method: 'DELETE',
        url: `${process.env.VUE_APP_API_URL}/api/alerts/destroy`,
        data: {wc_data: wc_data},
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
      }).then(() => {
        $('#clients-modal').modal('hide');
        this.getAlerts();
      });

    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
  }
}
</script>

<style scoped>
.scrollable-alerts {
  max-height: 320px;
}
</style>
import $ from "jquery";

export default function guard(to, from, next) {

    // Ajax Request to the REST API
    $.ajax({
        type: 'GET',
        url: process.env.VUE_APP_API_URL + '/api/checkToken',
        dataType: 'JSON',
        headers: {"Authorization": "Bearer " + localStorage.getItem('token')},
        success: (response) => {
            if (response.success) {
                next()
            } else {
                localStorage.clear();
                next({name: "login"})
            }
        },
        error: (error) => {
            if (error.status === 401) {
                localStorage.clear();
                next({name: 'login'})
            } else if (error.status === 429) {
                localStorage.clear();
                next({name: 'login'})
            }
        }
    });
}
<template>
  <admin-layout>
    <!-- 404 Error Text -->
    <div class="text-center">
      <div class="error mx-auto" data-text="404">404</div>
      <p class="lead text-gray-800 mb-5">Η Σελίδα Δεν Βρέθηκε</p>
      <p class="text-gray-500 mb-0">Προσπαθήσατε να εισέλθετε σε μία σελίδα που δεν υπάρχει...</p>
      <router-link :to="{ name: 'dashboard' }">&larr; Επιστροφή στην Αρχική</router-link>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import guard from "@/library/check-token";

export default {
  name: "NotFoundComponent",
  components: { AdminLayout },
  beforeRouteEnter(to, from, next) {
    guard(to, from, next)
  },
}
</script>

<style scoped>

</style>
<template>
  <admin-layout :active-menu="page.active" :page-title="page.title" :page-button="page.button">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Πίνακας Διαχείρισης Ειδών Παραστιτικών</h6>
        <span class="m-0 font-weight-bold text-muted">Είδη:</span>
        <span class="badge badge-pill badge-success ml-2">Απόδειξη</span>
        <span class="badge badge-pill badge-secondary ml-2">Τιμολόγιο</span>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered" id="invoice-types-datatable">
            <thead>
            <tr>
              <th><i class="fas fa-file-invoice-dollar mr-2"></i>Είδος Παραστατικού</th>
              <th><i class="fas fa-layer-group mr-2"></i>Σειρά</th>
              <th><i class="fas fa-sort mr-2"></i>Τελευταίος Αριθμός</th>
              <th><i class="fas fa-calendar mr-2"></i>Ημ/νία Δημιουργίας</th>
              <th><i class="fas fa-calendar mr-2"></i>Τελευταία Τροποποίηση</th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>

      <ModalComponent id="invoice-type-modal" :title="modal.title" :button="modal.button" :action="modal.action" :size="'sm'">
        <form id="invoice-type-form">

          <div class="row">
            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-shopping-bag"></i></span>
                </div>
                <select class="form-control" id="input-type" name="input-type" v-model="inputs.type">
                  <option value="0" disabled>Είδος</option>
                  <option value="1">Απόδειξη</option>
                  <option value="2">Τιμολόγιο</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label for="input-last-num">Σειρά</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-layer-group"></i></span></div>
                <input type="text" class="form-control" placeholder="Σειρά" id="input-series" name="input-series" v-model="inputs.series">
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label for="input-last-num">Τελευταίος Αριθμός</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-sort"></i></span></div>
                <input type="number" class="form-control" placeholder="Τελευταίος Αριθμός" id="input-last-num" name="input-last-num" v-model="inputs.last_num">
              </div>
            </div>
          </div>


        </form>
      </ModalComponent>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import guard from "@/library/check-token";
import $ from "jquery";
import catchCrudErrors from "@/library/ErrorHandling";
import datatableLanguages from "@/library/DatatableLanguages";
import catchDatatableErrors from "@/library/error-handling/Datatable";
import {destroyElement, editElement, storeElement, updateElement} from "@/library/CRUD";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "InvoiceTypesView",
  components: {ModalComponent, AdminLayout },
  beforeRouteEnter(to, from, next) {
    guard(to, from, next)
  },
  created() {
    window.prepare = this.prepare;
    window.edit = this.edit;
    window.destroy = this.destroy;
  },
  data() {
    return {
      page: {
        active: "invoice-types",
        title: '<i class="fas fa-fw fa-file-invoice-dollar mr-2"></i>Είδη Παραστατικών',
        button: '<button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onclick="prepare()"><i class="fas fa-plus fa-sm text-white-50"></i> Προσθήκη Νέου Είδους Παραστατικού</button>'
      },
      table: null,
      form: "#invoice-type-form",
      inputs: {
        id: 0,
        type: 0,
        series: "",
        last_num: 0
      },
      modal: {
        title: null,
        button: null,
        action: null
      },
    }
  },
  mounted() {
    this.table = $("#invoice-types-datatable").DataTable({
      initComplete: function () {
        this.api()
            .columns()
            .every(function () {
              const that = this;

              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this.value) {
                  that.search(this.value).draw();
                }
              });
            });
      },
      columnDefs: [
        {data: null, name: 'type_description', render: (data) => this.getTypeSpan(data), orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
        {data: 'series', name: 'series', orderable: true, className: 'dt-center', targets: 1, width: 'auto'},
        {data: 'last_num', name: 'last_num', orderable: true, className: 'dt-center', targets: 2, width: 'auto'},
        {data: null, name: 'created_date', render: (data) => this.getCreatedDate(data), orderable: true, className: 'dt-center', targets: 3, width: 'auto'},
        {data: null, name: 'updated_date', render: (data) => this.getUpdatedDate(data), orderable: true, className: 'dt-center', targets: 4, width: 'auto'},
        {data: null, render: (data) => this.getActionButtons(data.id), orderable: false, searchable: false, className: 'dt-center', targets: [5], width: 'auto'}
      ],
      language: datatableLanguages,
      autoWidth: false,
      processing: true,
      serverSide: true,
      ajax: {
        type: 'POST',
        url: `${process.env.VUE_APP_API_URL}/api/invoice-types/data`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        error: (error) => catchDatatableErrors(error)
      },
    })
  },
  methods: {
    prepare() {
      // Ajax Request to the REST API
      $.ajax({
        type: 'GET',
        url: `${process.env.VUE_APP_API_URL}/api/invoice-types/prepare`,
        dataType: 'JSON',
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        success: (response) => {
          this.modal = response
          this.modal.action = this.store;
          this.resetData();
          $("#invoice-type-modal").modal("show");
        },
        error: (error) => catchCrudErrors(error)
      });
    },
    store() {
      // Serialize the form
      $(this.form).serialize();
      let form = $(this.form);
      let formData = new FormData(form[0])

      storeElement(formData, `${process.env.VUE_APP_API_URL}/api/invoice-types/store`, this.table, $("#invoice-type-modal"))
    },
    edit(id) {
      let success = (response) => {
        this.inputs = response.data;
        this.modal = response.modal;
        this.modal.action = this.update;
        $("#invoice-type-modal").modal("show");
      }
      editElement(id, `${process.env.VUE_APP_API_URL}/api/invoice-types/edit`, success);
    },
    update() {
      // Serialize the form
      $(this.form).serialize();
      let form = $(this.form);
      let formData = new FormData(form[0])

      // Append the ID to the form data object
      formData.append('input-id', this.inputs.id);

      updateElement(formData, `${process.env.VUE_APP_API_URL}/api/invoice-types/update`, this.table, $("#invoice-type-modal"));
    },
    destroy(id) {
      destroyElement(id, `${process.env.VUE_APP_API_URL}/api/invoice-types/destroy`, 'Αυτή η ενέργεια θα διαγράψει μόνιμα το είδος παραστατικού!', this.table);
    },
    resetData() {
      this.inputs = {
        id: 0,
        type: 0,
        series: "",
        last_num: 0
      }
    },
    getTypeSpan(data) {
      return `<span class="badge badge-pill badge-${data.type === 1 ? 'success' : 'secondary'} ml-2">${data.type_description}</span>`;
    },
    getCreatedDate(data) {
      return `<i class="fas fa-calendar-check mr-1"></i>${data.created_date} <i class="fas fa-clock ml-2 mr-1"></i>${data.created_time}`;
    },
    getUpdatedDate(data) {
      return `<i class="fas fa-calendar-check mr-1"></i>${data.updated_date} <i class="fas fa-clock ml-2 mr-1"></i>${data.updated_time}`;
    },
    getActionButtons(id) {
      let viewButton = `<button class="btn btn-outline-warning btn-sm mr-1" onclick="edit(${id})"><i class="fas fa-edit"></i></button>`;
      let editButton = `<button class="btn btn-outline-danger btn-sm ml-1" onclick="destroy(${id})"><i class="fas fa-trash"></i></button>`;
      return viewButton + editButton;
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <admin-layout :active-menu="page.active" :page-title="page.title" :page-button="page.button">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Πίνακας Διαχείρισης Φ.Π.Α. Χωρών</h6>
        <span class="m-0 font-weight-bold text-muted">Είδη:</span>
        <span class="badge badge-pill badge-success ml-2">Λιανική</span>
        <span class="badge badge-pill badge-secondary ml-2">Χονδρική</span>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered" id="countries-vats-datatable">
            <thead>
            <tr>
              <th><i class="fas fa-globe mr-2"></i>Χώρα</th>
              <th><i class="fas fa-funnel-dollar mr-2"></i>Φ.Π.Α.</th>
              <th><i class="fas fa-shopping-bag mr-2"></i>Είδος</th>
              <th><i class="fas fa-comments mr-2"></i>Περιγραφή</th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>

      <ModalComponent id="country-vat-modal" :title="modal.title" :button="modal.button" :action="modal.action">
        <form id="country-vat-form">

          <div class="row">

            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-globe"></i></span>
                </div>
                <select class="form-control" id="input-country" name="input-country" v-model="inputs.country">
                  <option value="0" selected disabled>Χώρα</option>
                  <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                </select>
              </div>
            </div>

            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-shopping-bag"></i></span>
                </div>
                <select class="form-control" id="input-type" name="input-type" v-model="inputs.type">
                  <option value="0" disabled>Είδος</option>
                  <option value="1">Λιανική</option>
                  <option value="2">Χονδρική</option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">

            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-percentage"></i></span></div>
                <input type="number" class="form-control" placeholder="Αξία" id="input-value" name="input-value" v-model="inputs.value">
              </div>
            </div>

            <div class="col">
              <div class="input-group mb-3">
                <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-comments"></i></span></div>
                <input type="text" class="form-control" placeholder="Περιγραφή" id="input-description" name="input-description" v-model="inputs.description">
              </div>
            </div>
          </div>

        </form>
      </ModalComponent>
    </div>
  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import guard from "@/library/check-token";
import $ from "jquery";
import catchCrudErrors from "@/library/ErrorHandling";
import datatableLanguages from "@/library/DatatableLanguages";
import catchDatatableErrors from "@/library/error-handling/Datatable";
import ModalComponent from "@/components/ModalComponent.vue";
import {destroyElement, editElement, storeElement, updateElement} from "@/library/CRUD";
import axios from "axios";

export default {
  name: "CountriesVatsView",
  components: { AdminLayout, ModalComponent },
  beforeRouteEnter(to, from, next) {
    guard(to, from, next)
  },
  created() {
    window.prepare = this.prepare;
    window.edit = this.edit;
    window.destroy = this.destroy;
  },
  data() {
    return {
      page: {
        active: "countries-vats",
        title: '<i class="fas fa-fw fa-funnel-dollar mr-2"></i>Φ.Π.Α. Χωρών',
        button: '<button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onclick="prepare()"><i class="fas fa-plus fa-sm text-white-50"></i> Προσθήκη Νέου Φ.Π.Α. Χώρας</button>'
      },
      table: null,
      form: "#country-vat-form",
      inputs: {
        id: 0,
        country: 0,
        value: 0,
        type: 0,
        description: ""
      },
      modal: {
        title: null,
        button: null,
        action: null
      },
      countries: {}
    }
  },
  mounted() {

    // Ajax Request to the REST API
      axios.get(`${process.env.VUE_APP_API_URL}/api/countries/getAll`, {
          headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
      }).then(response => {
          this.countries = response.data
      }).catch(
          error => catchCrudErrors(error.response)
      )

    this.table = $("#countries-vats-datatable").DataTable({
      initComplete: function () {
          this.api()
              .columns()
              .every(function () {
                  const that = this;

                  $('input', this.footer()).on('keyup change clear', function () {
                      if (that.search() !== this.value) {
                          that.search(this.value).draw();
                      }
                  });
              });
      },
      columnDefs: [
        {data: 'country', orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
        {data: 'value', orderable: true, className: 'dt-center', targets: 1, width: 'auto'},
        {data: 'type', render: (type) => this.getCountryVatType(type), orderable: true, className: 'dt-center', targets: 2, width: 'auto'},
        {data: 'description', orderable: true, className: 'dt-center', targets: 3, width: 'auto'},
        {data: null, render: (data) => this.getActionButtons(data.id), orderable: false, className: 'dt-center', targets: 4, width: 'auto'}
      ],
      language: datatableLanguages,
      autoWidth: false,
      processing: true,
      serverSide: true,
      ajax: {
        type: 'POST',
        url: `${process.env.VUE_APP_API_URL}/api/countries-vats/data`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        error: (error) => catchDatatableErrors(error)
      },
    })
  },
  methods: {
    getActionButtons(id) {
        const viewButton = `<button class="btn btn-outline-warning btn-sm mr-1" id="edit-country-vat-${id}" onclick="edit(${id})"><i class="fas fa-edit"></i></button>`;
        const editButton = `<button class="btn btn-outline-danger btn-sm ml-1" id="destroy-country-vat-${id}" onclick="destroy(${id})"><i class="fas fa-trash"></i></button>`;
        return viewButton + editButton;
    },
    getCountryVatType(type) {
        return type === 1 ? '<span class="badge badge-pill badge-success">Λιανική</span>' : '<span class="badge badge-pill badge-secondary">Χονδρική</span>';
    },
    prepare() {
      // Ajax Request to the REST API
      $.ajax({
        type: 'GET',
        url: `${process.env.VUE_APP_API_URL}/api/countries-vats/prepare`,
        dataType: 'JSON',
          headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        success: (response) => {
          this.modal = response
          this.modal.action = this.store;
          this.resetData();
          $("#country-vat-modal").modal("show");
        },
        error: (error) => catchCrudErrors(error)
      });
    },
    store() {
      // Serialize the form
      $(this.form).serialize();
      let form = $(this.form);
      let formData = new FormData(form[0])

      storeElement(formData, `${process.env.VUE_APP_API_URL}/api/countries-vats/store`, this.table, $("#country-vat-modal"))
    },
    edit(id) {
      let success = (response) => {
        this.inputs = response.data;
        this.modal = response.modal;
        this.modal.action = this.update;
        $("#country-vat-modal").modal("show");
      }
      editElement(id, `${process.env.VUE_APP_API_URL}/api/countries-vats/edit`, success);
    },
    update() {
      // Serialize the form
      $(this.form).serialize();
      let form = $(this.form);
      let formData = new FormData(form[0])

      // Append the ID to the form data object
      formData.append('input-id', this.inputs.id);

      updateElement(formData, `${process.env.VUE_APP_API_URL}/api/countries-vats/update`, this.table, $("#country-vat-modal"));
    },
    destroy(id) {
      destroyElement(id, `${process.env.VUE_APP_API_URL}/api/countries-vats/destroy`, 'Αυτή η ενέργεια θα διαγράψει μόνιμα το Φ.Π.Α. της χώρας!', this.table);
    },
    resetData() {
      this.inputs = {
        id: 0,
        country: 0,
        value: 0,
        type: 0,
        description: ""
      }
    }
  }
}
</script>

<style scoped>

</style>
import catchCrudErrors from "@/library/ErrorHandling";
import {createToaster} from "@meforma/vue-toaster";
import Swal from "sweetalert2";
import axios from "axios";

const toaster = createToaster();

export function prepareElement(route, success) {
    localStorage.setItem('loading', true);

    axios.get(route, {
        headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
    }).then(
        response => success(response.data)
    ).catch(
        (data) => catchCrudErrors(data.response)
    ).finally(
        () => localStorage.setItem('loading', false)
    );
}

export function storeElement(formData, route, table, modal, successHandler) {

    const success = successHandler || ((response) => {
        toaster.success(response.message)
        if (table) {
            table.ajax.reload(null, false);
        }
        if (modal) {
            modal.modal('hide');
        }
    })

    localStorage.setItem('loading', true);

    axios.post(route, formData, {
        headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
    }).then(
        response => success(response.data)
    ).catch(
        (data) => catchCrudErrors(data.response)
    ).finally(
        () => localStorage.setItem('loading', false)
    );
}

export function editElement(id, route, success) {
    localStorage.setItem('loading', true);

    axios.post(route, {'input-id': id}, {
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
    }).then(
        (response) => success(response.data)
    ).catch(
        (data) => catchCrudErrors(data.response)
    ).finally(
        () => localStorage.setItem('loading', false)
    );
}

export function updateElement(formData, route, table, modal, successHandler) {
    const success = successHandler || ((response) => {
        toaster.success(response.data.message)
        if (table) {
            table.ajax.reload(null, false);
        }
        if (modal) {
            modal.modal('hide');
        }
    })

    localStorage.setItem('loading', true);
    axios.patch(route, Object.fromEntries(formData), {
        headers: {"Authorization": "Bearer " + localStorage.getItem('token')}
    }).then(
        response => success(response)
    ).catch(
        error => catchCrudErrors(error.response)
    ).finally(
        () => localStorage.setItem('loading', false)
    );

}

export function destroyElement(id, route, text, table) {
    Swal.fire({
        title: 'Προσοχή',
        text: text,
        icon: 'warning',
        confirmButtonText: 'Επιβεβαίωση',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση'
    }).then((result) => {
        localStorage.setItem('loading', true);
        if (result.isConfirmed) {
            axios({
                method: 'DELETE',
                url: route,
                data: {'input-id': id},
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
            }).then(data => {
                toaster.success(data.data.message);
                table.ajax.reload(null, false);
            }).catch(
                data => catchCrudErrors(data.response)
            ).finally(
                () => localStorage.setItem('loading', false)
            );

        }
    })
}
import { createRouter, createWebHistory } from 'vue-router'

import LoginView from "@/views/auth/LoginView";
import ForgotPasswordView from "@/views/auth/ForgotPasswordView";
import ResetPasswordView from "@/views/auth/ResetPasswordView";

import DashboardView from '../views/dashboard/DashboardView.vue'
import ClientsView from "@/views/clients/ClientsView.vue";
import ProductsView from "@/views/products/ProductsView.vue";
import StockVue from "@/views/stock/StockView.vue";
import InvoicesView from "@/views/invoices/InvoicesView.vue";
import InvoiceManagement from "@/views/invoices/InvoiceManagement.vue";
import InvoiceTypesView from "@/views/invoiceTypes/InvoiceTypesView.vue";
import CountriesView from "@/views/countries/CountriesView.vue";
import CountriesVatsView from "@/views/countiresVats/CountriesVatsView.vue";
import AdminsView from "@/views/admins/AdminsView.vue";

import ActivityLogView from "@/views/activityLog/ActivityLogView.vue";

import NotFoundComponent from "@/views/NotFoundView";

const routes = [
  { path: '/login', name: 'login', component: LoginView },
  { path: '/forgot-password', name: 'forgot-password', component: ForgotPasswordView },
  { path: '/reset-password/:token', name: 'reset-password', component: ResetPasswordView },

  { path: '/', name: 'dashboard', component: DashboardView },
  { path: '/clients', name: 'clients', component: ClientsView },
  { path: '/products', name: 'products', component: ProductsView },
  { path: '/stock', name: 'stock', component: StockVue },
  { path: '/invoices', name: 'invoices', component: InvoicesView },
  { path: '/invoices/create', name: 'invoice-create', component: InvoiceManagement },
  // { path: '/invoices/edit/{id}', name: 'invoice-edit', component: InvoiceManagement },
  { path: '/invoice-types', name: 'invoice-types', component: InvoiceTypesView },
  { path: '/countries', name: 'countries', component: CountriesView },
  { path: '/countries-vats', name: 'countries-vats', component: CountriesVatsView },
  { path: '/admins', name: 'admins', component: AdminsView },

  { path: '/activity-log', name: 'activity-log', component: ActivityLogView },

  { name: 'NotFound', path: '/:catchAll(.*)', component: NotFoundComponent },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router

import { createToaster } from "@meforma/vue-toaster";

export default function catchDatatableErrors (error) {
    const toaster = createToaster();

    if (error.status === 401) {
        toaster.error('Σφάλμα διαπιστευτηρίων! Αποσύνδεση...')
        localStorage.clear();
        location.href = '/login';
    } else if (error.status === 429) {
        toaster.error('Υπερβολικά πολλά αιτήματα! Αποσύνδεση...')
        localStorage.clear();
        location.href = '/login';
    }
}
<template>
  <admin-layout :active-menu="page.active" :page-title="page.title" :page-button="this.stockActions.length > 0 ? page.cancelBtn + page.submitBtn : page.createBtn">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Κινήσεις Αποθήκης</h6>
      </div>
      <div class="card-body">

        <div class="mb-5" data-content="create-new-stock-action-area" v-if="stockActions.length > 0">
          <div class="row">
            <div class="col">
              <label for="input-date">Ημερομηνία κίνησης</label>
              <datepicker class="form-control" :locale="datepickerConfig.locale" inputFormat="dd-MM-yyyy" v-model="date">test</datepicker>
            </div>
            <div class="col">
              <label for="input-reason">Αιτιολογία κίνησης</label>
              <input type="text" id="input-reason" class="form-control" v-model="reason">
            </div>
          </div>
          <hr>
          <div class="row" v-for="(item, index) in stockActions" :key="index">
            <div class="col-3">
                <vue-select v-model="stockActions[index].code"
                            placeholder="Κωδικός Προϊόντος"
                            label="code" value="id"
                            :options="productCodesList"
                            :filterable="false"
                            @search="filterProductsByCode"
                            @option:selected="selectProduct($event, index)"
                            :clearable="false"
                ></vue-select>
            </div>
            <div class="col-4">
                <vue-select v-model="stockActions[index].name"
                            placeholder="Τίτλος Προϊόντος"
                            label="name" value="id"
                            :options="productNamesList"
                            :filterable="false"
                            @search="filterProductsByDescription"
                            @option:selected="selectProduct($event, index)"
                            :clearable="false"
                ></vue-select>
            </div>
            <div class="col-3">
              <div class="input-group mb-3">
                <span v-if="width >= 1400" class="input-group-text" id="basic-addon1">Ποσότητα</span>
                <span v-else class="input-group-text" id="basic-addon1"><i class="fas fa-th-list"></i></span>
                <input type="number" :id="'input-product-quantity-' + index" class="form-control" v-model="item.quantity">
              </div>
            </div>
            <div class="col-2">
              <button class="btn btn-outline-secondary" :id="'delete-row-btn-' + index" @click="removeStockAction(index)" title="Διαγραφή"><i class="fas fa-trash-alt"></i></button>
              <button class="btn btn-outline-secondary ml-2" id="add-row-btn" @click="addStockAction()" title="Προσθήκη" v-if="stockActions.length === (index+1) && stockActions.length < 10 && item.id > 0"><i class="fas fa-plus"></i></button>
            </div>
          </div>
          <hr>
        </div>

        <div class="table-responsive" data-content="stock-actions-table-area">
          <table class="table table-bordered" id="stock-actions-datatable">
            <thead>
            <tr>
              <th><i class="fas fa-calendar-alt mr-2"></i>Ημ/νία</th>
              <th><i class="fas fa-comment-alt mr-2"></i>Αιτιολογία</th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><i class="fas fa-calendar-alt mr-2"></i>Ημ/νία</th>
              <th><i class="fas fa-comment-alt mr-2"></i>Αιτιολογία</th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>

      </div>
    </div>

    <!-- Stock Modal-->
    <modal-component id="stock-modal" :title="modal.title" :button="modal.button">
      <form id="log-form">
        <div class="row">
          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
              </div>
              <input type="text" class="form-control" id="input-date" name="input-date" v-model="inputs.date" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-comments"></i></span>
              </div>
              <input type="text" class="form-control" id="input-reason" name="input-reason" v-model="inputs.reason" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-shopping-basket"></i></span>
              </div>
              <textarea type="text" class="form-control" id="input-actions" name="input-actions" rows="10" v-model="inputs.actions" readonly></textarea>
            </div>
          </div>
        </div>
      </form>
    </modal-component>


  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import $ from 'jquery';
import Datepicker from 'vue3-datepicker'
import {el} from 'date-fns/locale'
import catchCrudErrors from "@/library/ErrorHandling";
import datatableLanguages from "@/library/DatatableLanguages";
import catchDatatableErrors from "@/library/error-handling/Datatable";
import ModalComponent from "@/components/ModalComponent.vue";
import {storeElement} from "@/library/CRUD";
import VueSelect from "vue-select";

export default {
  name: "StockVue",
  components: {VueSelect, ModalComponent, AdminLayout, Datepicker},
  created() {
    window.addStockAction = this.addStockAction;
    window.submitStoreActions = this.submitStoreActions;
    window.abortStoreActions = this.abortStoreActions;
    window.details = this.details;
  },
  data() {
    return {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      page: {
        active: "stock",
        title: '<i class="fas fa-fw fa-truck-loading mr-2"></i>Αποθήκη',
        submitBtn: '<button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-2" onclick="submitStoreActions()"><i class="fas fa-save fa-sm text-white-50"></i> Υποβολή</button>',
        cancelBtn: '<button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-2" onclick="abortStoreActions()"><i class="fas fa-times fa-sm text-white-50"></i> Ακύρωση</button>',
        createBtn: '<button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-2" onclick="addStockAction()"><i class="fas fa-plus fa-sm text-white-50"></i> Προσθήκη Νέας Κίνησης Αποθήκης</button>',
      },
      modal: {
        title: '<i class="fas fa-fw fa-list mr-2"></i>Αναλυτική Προβολή Ενέργειας'
      },
      reason: '',
      inputs: {
        date: '',
        reason: '',
        actions: ''
      },
      table: null,
      date: new Date(),
      datepickerConfig: {
        locale: el
      },
      stockActionDropdowns: [
        {code: null, description: null}
      ],
      stockActions: [
        // {id: 0, quantity: 0}
      ],
      productCodesList: [],
      productNamesList: [],
      dropdowns: [],
      products: []
    }
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions);

    this.table = $("#stock-actions-datatable").DataTable({
      columnDefs: [
        {orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
        {orderable: true, className: 'dt-center', targets: 1, width: 'auto'},
        {orderable: false, className: 'dt-center', targets: 2, width: 'auto'}
      ],
      language: datatableLanguages,
      autoWidth: false,
      processing: true,
      serverSide: true,
      ajax: {
        type: 'POST',
        url: `${process.env.VUE_APP_API_URL}/api/products/stock/data`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        error: (error) => catchDatatableErrors(error)
      },
    })

  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },
  methods: {
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
    },
    addStockAction() {
      this.stockActions.push({id: 0, quantity: 0})
      // let lastInsertedIndex = (this.stockActions.length - 1);

      this.$nextTick(function() {

      })
    },
    removeStockAction(position) {
      this.stockActions.splice(position, 1)
    },
    filterProductsByCode(search, loading) {
        this.emptyLists();
        if (search) {
            let selected = [];
            this.stockActions.forEach((item) => {
                selected.push(item.id)
            });

            $.ajax({
                type: 'POST',
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                url: `${process.env.VUE_APP_API_URL}/api/products/filterByCode`,
                dataType: 'json',
                data: {code: search, selected: selected},
                beforeSend: () => loading(true),
                success: (response) => {
                    response.results.forEach((item) => {
                        this.productCodesList.push({
                            'id': item.id,
                            'title': item.title,
                            'code': item.code,
                            'name': item.name,
                            'price': item.price,
                            'subtotal': 0,
                            'quantity': 1,
                            'unit': item.unit,
                            'stock': item.stock
                        });
                    })
                },
                complete: () => loading(false),
                error: catchCrudErrors,
                cache: false
            });
        }
    },
    filterProductsByDescription(search, loading) {
        this.emptyLists();
        if (search) {
            let selected = [];
            this.stockActions.forEach((item) => {
                selected.push(item.id)
            });

            $.ajax({
                type: 'POST',
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                url: `${process.env.VUE_APP_API_URL}/api/products/filterByDescription`,
                dataType: 'json',
                data: {description: search, selected: selected},
                beforeSend: () => loading(true),
                success: (response) => {
                    this.productCodesList = [];
                    this.productNamesList = [];
                    response.results.forEach((item) => {
                        this.productNamesList.push({
                            'value': item.id,
                            'label': item.code,
                            'id': item.id,
                            'title': item.title,
                            'code': item.code,
                            'name': item.name,
                            'price': item.price,
                            'subtotal': 0,
                            'quantity': 1,
                            'unit': item.unit,
                            'stock': item.stock
                        });
                    })
                },
                complete: () => loading(false),
                error: catchCrudErrors,
                cache: false
            });
        }
    },
    selectProduct(object, index) {
        this.stockActions[index] = object;
        this.emptyLists();
    },
    submitStoreActions() {
      if (!this.date) {
        this.$toast.error('Η ημερομηνία είναι υποχρεωτικό πεδίο!')
        return;
      }
      if (!this.reason) {
        this.$toast.error('Η αιτιολογία είναι υποχρεωτικό πεδίο!')
        return;
      }
      let invalidId = this.stockActions.find(value => {
        return value.id === '' || value.id === 0;
      })
      let invalidQuantity = this.stockActions.find(value => {
        return value.quantity === '' || value.quantity === 0;
      })
      if (invalidId) {
        this.$toast.error('Βεβαιωθείτε ότι όλες οι εγγραφές έχουν έγκυρο προϊόν');
        return;
      }
      if (invalidQuantity) {
        this.$toast.error('Βεβαιωθείτε ότι όλες οι εγγραφές έχουν έγκυρη ποσότητα');
        return;
      }

      let data = new FormData();
      let date = new Date(this.date)
      data.append('date', date.toISOString());
      data.append('reason', this.reason);
      this.stockActions.forEach((item, index) => {
        data.append(`product_id[${index}]`, item.id)
        data.append(`quantity[${index}]`, item.quantity)
      })

      const successHandler = ((response) => {
        this.table.ajax.reload(null, false);
        this.abortStoreActions();
        this.$toast.success(response.message);
      });

      storeElement(data, `${process.env.VUE_APP_API_URL}/api/products/updateStock`, this.table, null, successHandler)
    },
    abortStoreActions() {
      this.stockActions = [];
      this.productCodesList = [];
      this.productNamesList = [];
      this.reason = '';
    },
    details(id) {
      // Ajax Request to the REST API
      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_API_URL}/api/products/stock/info`,
        data: {"id": id},
        dataType: 'JSON',
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        success: (response) => {
          this.inputs.date = response.data.date;
          this.inputs.reason = response.data.reason;
          this.inputs.actions = '';
          response.actions.forEach((item) => {
            this.inputs.actions += `${item.code} [${item.title}] \u2794 ${item.quantity > 0 ? '+' + item.quantity : item.quantity} \n`
          })
          $("#stock-modal").modal('show');
        },
        error: (error) => catchCrudErrors(error)
      });
    },
    emptyLists() {
        this.productCodesList = [];
        this.productNamesList = [];
    }
  }
}
</script>

<style scoped>

</style>
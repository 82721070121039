<template>
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800" v-html="title"/>
    <span v-if="button" v-html="button"/>
  </div>
</template>

<script>
export default {
  name: "PageHeadingComponent",
  props: ['title', 'button']
}
</script>

<style scoped>

</style>
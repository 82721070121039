<template>
  <admin-layout :active-menu="page.active" :page-title="page.title" :page-button="page.button">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary mb-2">Πίνακας Ενεργειών</h6>
        <span class="m-0 font-weight-bold text-muted">Κατηγορίες:</span>
        <span class="badge badge-pill badge-danger ml-2">Κρίσιμη</span>
        <span class="badge badge-pill badge-warning ml-2">Προειδοποίησης</span>
        <span class="badge badge-pill badge-success ml-2">Εισόδου</span>
        <span class="badge badge-pill badge-info ml-2">Ροής</span>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered" id="activity-logs-datatable">
            <thead>
            <tr>
              <th><i class="fas fa-user-shield mr-2"></i>Διαχειριστής</th>
              <th><i class="fas fa-comments mr-2"></i>Τύπος Ενέργειας</th>
              <th><i class="fas fa-comments mr-2"></i>Περιγραφή</th>
              <th><i class="fas fa-network-wired mr-2"></i>Διεύθυνση IP</th>
              <th><i class="fas fa-calendar-alt mr-2"></i>Ημερομηνία & Ώρα</th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><i class="fas fa-user-shield mr-2"></i>Διαχειριστής</th>
              <th><i class="fas fa-comments mr-2"></i>Τύπος Ενέργειας</th>
              <th><i class="fas fa-comments mr-2"></i>Περιγραφή</th>
              <th><i class="fas fa-network-wired mr-2"></i>Διεύθυνση IP</th>
              <th><i class="fas fa-calendar-alt mr-2"></i>Ημερομηνία & Ώρα</th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Logs Modal-->
    <modal-component id="log-modal" :title="modal.title">
      <form id="log-form">
        <div class="row">
          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-network-wired"></i></span>
              </div>
              <input type="text" class="form-control" id="input-ip" name="input-ip" v-model="inputs.ip" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-comments"></i></span>
              </div>
              <input type="text" class="form-control" id="input-type" name="input-type" v-model="inputs.type" readonly>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-comments"></i></span>
              </div>
              <textarea type="text" class="form-control" id="input-description" name="input-description" rows="10" v-model="inputs.description" readonly></textarea>
            </div>
          </div>
        </div>
      </form>
    </modal-component>

  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import ModalComponent from "@/components/ModalComponent.vue";

import $ from "jquery";

import datatableLanguages from "@/library/DatatableLanguages";
import catchDatatableErrors from "@/library/error-handling/Datatable";
import catchCrudErrors from "@/library/ErrorHandling";
import Swal from "sweetalert2";
import guard from "@/library/check-token";

export default {
  name: "ActivityLogView",
  components: {AdminLayout, ModalComponent},
  beforeRouteEnter(to, from, next) {
    guard(to, from, next)
  },
  created() {
    window.viewLog = this.viewLog;
    window.clearLogs = this.clearLogs;
  },
  data() {
    return {
      page: {
        title: '<i class="fas fa-fw fa-list mr-2"></i>Ιστορικό Ενεργειών',
        button: '<button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onclick="clearLogs()"><i class="fas fa-trash-alt mr-1 fa-sm text-white-50"></i> Καθαρισμός όλων</button>'
      },
      modal: {
        title: '<i class="fas fa-fw fa-list mr-2"></i>Αναλυτική Προβολή Ενέργειας'
      },
      inputs: {
        type: null,
        description: null,
        ip: null
      },
      table: null
    }
  },
  mounted() {

    this.table = $("#activity-logs-datatable").DataTable({
      columnDefs: [
        {orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
        {orderable: true, className: 'dt-center', targets: 1, width: 'auto'},
        {orderable: true, className: 'dt-center', targets: 2, width: 'auto'},
        {orderable: true, className: 'dt-center', targets: 3, width: 'auto'},
        {orderable: true, className: 'dt-center', targets: 4, width: 'auto'},
        {orderable: false, className: 'dt-center', targets: 5, width: 'auto'}
      ],
      order: [[4, 'desc']],
      language: datatableLanguages,
      autoWidth: false,
      processing: true,
      serverSide: true,
      ajax: {
        type: 'POST',
        url: `${process.env.VUE_APP_API_URL}/api/logs/data`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        error: (error) => catchDatatableErrors(error)
      },
    })
  },
  methods: {
    viewLog(id) {
      // Ajax Request to the REST API
      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_API_URL}/api/logs/info`,
        data: {"id": id},
        dataType: 'JSON',
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        success: (response) => {
          this.inputs = response.data;
          $("#log-modal").modal('show');
        },
        error: (error) => catchCrudErrors(error)
      });

    },
    clearLogs() {

      Swal.fire({
        title: 'Προσοχή',
        text: 'Αυτή η ενέργεια θα καθαρίσει όλο το ιστορικό των ενεργειών!',
        icon: 'warning',
        confirmButtonText: 'Επιβεβαίωση',
        showCancelButton: true,
        cancelButtonText: 'Ακύρωση'
      }).then((result) => {
        if (result.isConfirmed) {
          // Ajax Request to the REST API
          $.ajax({
            type: 'DELETE',
            url: `${process.env.VUE_APP_API_URL}/api/logs/clear`,
            dataType: 'JSON',
              headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            success: (response) => {
              this.$toast.success(response.message)
              this.table.ajax.reload(null, false);
            },
            error: (error) => catchCrudErrors(error)
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div id="invoice">

      <table style="width: 100%;">
          <tr>
              <td><img src="@/assets/img/logo.png" alt="logo"></td>
              <td align="right">
                  <b style="font-size: 30pt">{{ business.name }}</b><br />
                  {{ business.address }} <br />
                  Email: <a :href="`mailto:${business.email}`">{{ business.email }}</a> - Website: <a target="_blank" :href="business.website">{{ business.name }}</a>
              </td>
          </tr>
      </table>
      <table style="width: 100%">
          <tr style="width: 100%">
              <td colspan="2" style="width: 100%" align="right"><h1>Original Invoice/фактура</h1></td>
          </tr>
          <tr style="width: 100%">
              <td colspan="2" style="width: 100%" align="right"><br />Invoice/фактура : {{ invoice.series }} {{ invoice.number }}</td>
          </tr>
          <tr style="width: 100%">
              <td colspan="2" style="width: 100%" align="right">Date/дата : {{ new Date(invoice.date).toLocaleDateString('el-GR', { day: 'numeric', month: 'numeric', year: 'numeric' }) }}<br /></td>
          </tr>
          <tr style="width: 100%">
              <td colspan="2" style="width: 100%" align="right"><hr /></td>
          </tr>
          <tr>
              <td width="50%">To/за :<br />
                  {{ invoice.client.company ? invoice.client.company : invoice.client.name}} <br v-if="invoice.client.company || invoice.client.name"/>
                  {{ invoice.client.job ?? '' }} <br v-if="invoice.client.job"/>
                  {{ invoice.client.address ?? '' }} <br v-if="invoice.client.address"/>
                  {{ invoice.client.city ?? '' }}<span v-if="invoice.client.city && invoice.client.country">,</span> {{ invoice.client.country ?? '' }}<br v-if="invoice.client.city || invoice.client.country"/>
                  {{ invoice.client.afm ?? '' }}
              </td>
              <td width="50%">Ship to/Кораб за:<br />
                  {{ invoice.client.company ? invoice.client.company : invoice.client.name}} <br v-if="invoice.client.company || invoice.client.name"/>
                  {{ invoice.client.job ?? '' }} <br v-if="invoice.client.job"/>
                  {{ invoice.client.address ?? '' }} <br v-if="invoice.client.address"/>
                  {{ invoice.client.city ?? '' }}<span v-if="invoice.client.city && invoice.client.country">,</span> {{ invoice.client.country ?? '' }}<br v-if="invoice.client.city || invoice.client.country"/>
                  {{ invoice.client.afm ?? '' }}
              </td>
          </tr>
          <tr style="width: 100%">
              <td colspan="2" width="100%" align="right"><hr /></td>
          </tr>
      </table>
      <table width="100%" cellpadding="1" cellspacing="1">
          <tr>
              <td style="width:12%;border-bottom:1pt solid black;font-size:small;text-align:left;">Qty<br />количество</td>
              <td style="width:8%;border-bottom:1pt solid black;font-size:small;text-align:left;">Item<br />точка</td>
              <td style="width:40%;border-bottom:1pt solid black;font-size:small;text-align:left;">Description<br />описание</td>
              <td style="width:20%;border-bottom:1pt solid black;font-size:small;text-align:right;">Unit Price<br />Единична цена</td>
              <td style="width:20%;border-bottom:1pt solid black;font-size:small;text-align:right;">Line Total<br />Онлайн общо</td>
          </tr>
          <tr v-for="(i, index) in invoice.products" :key="index">
              <td style="width:12%;border-bottom:1pt solid black;font-size:small;text-align:left;">{{ i.quantity }}</td>
              <td style="width:8%;border-bottom:1pt solid black;font-size:small;text-align:left;">{{ i.unit }}</td>
              <td style="width:40%;border-bottom:1pt solid black;font-size:small;text-align:left;">{{ i.name }}</td>
              <td style="width:20%;border-bottom:1pt solid black;font-size:small;text-align:right;">
                  <table width="100%">
                      <tr>
                          <td style="text-align:right;">{{ i.price ? parseFloat(i.price).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) : '' }}</td>
                          <td style="text-align:right;">{{ i.price ? (parseFloat(i.price) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '' }}</td>
                      </tr>
                  </table>
              </td>
              <td style="width:20%;border-bottom:1pt solid black;font-size:small;text-align:right;">
                  <table width="100%">
                      <tr>
                          <td style="text-align:right;">{{ i.subtotal ? parseFloat(i.subtotal).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) : '0.00 €' }}</td>
                          <td style="text-align:right;">{{ i.subtotal ? (parseFloat(i.subtotal) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '0.00 лв' }}</td>
                      </tr>
                  </table>
              </td>
          </tr>
      </table>
      <table width="100%">
          <tr>
              <td width="50%">
                  <table width="100%">
                      <tr>
                          <td style="width:100%;text-align:center;"><br /></td>
                      </tr>
                      <tr>
                          <td style="width:100%;text-align:center;"><br />Bank Account</td>
                      </tr>
                      <tr>
                          <td style="width:35%;font-size:small;text-align:left;">BENEFICIARY : </td>
                          <td style="width:65%;font-size:small;text-align:left;">ECONIK GROUP LTD</td>
                      </tr>
                      <tr>
                          <td style="width:35%;font-size:small;text-align:left;">IBAN : </td>
                          <td style="width:65%;font-size:small;text-align:left;">BG16UBBS80021444302810 (EURO)</td>
                      </tr>
                      <tr>
                          <td style="width:35%;font-size:small;text-align:left;">BENEF.BANK : </td>
                          <td style="width:65%;font-size:small;text-align:left;">UBB</td>
                      </tr>
                      <tr>
                          <td style="width:35%;font-size:small;text-align:left;">SWIFT/BIC : </td>
                          <td style="width:65%;font-size:small;text-align:left;">UBBSBGSF</td>
                      </tr>
                  </table>
              </td>
              <td style="width:50%;text-align:right;">
                  <table width="100%">
                      <tr>
                          <td style="width:60%;text-align:right;border-bottom:1pt solid black;">Subtotal/Междинна сума : </td>
                          <td style="width:40%;font-size:small;text-align:left;border-bottom:1pt solid black;">
                              <table width="100%">
                                  <tr>
                                      <td style="text-align:right;">{{ invoice.subTotal ? parseFloat(invoice.subTotal).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) : '0.00 €' }}</td>
                                        <td style="text-align:right;">{{ invoice.subTotal ? (parseFloat(invoice.subTotal) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '0.00 лв' }}</td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td style="width:60%;text-align:right;border-bottom:1pt solid black;">{{ invoice.vatValue }}% Sales Tax / ДДС : </td>
                          <td style="width:40%;font-size:small;text-align:left;border-bottom:1pt solid black;">
                              <table width="100%">
                                  <tr>
                                      <td style="text-align:right;">{{ invoice.vatTotal ? parseFloat(invoice.vatTotal).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) : '0.00 €' }}</td>
                                      <td style="text-align:right;">{{ invoice.vatTotal ? (parseFloat(invoice.vatTotal) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '0.00 лв' }}</td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                      <tr>
                          <td style="width:60%;text-align:right;border-bottom:1pt solid black;">Total / сума : </td>
                          <td style="width:40%;font-size:small;text-align:left;border-bottom:1pt solid black;">
                              <table width="100%">
                                  <tr>
                                      <td style="text-align:right;">{{ invoice.total ? parseFloat(invoice.total).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) : '0.00 €' }}</td>
                                      <td style="text-align:right;">{{ invoice.total ? (parseFloat(invoice.total) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '0.00 лв' }}</td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                  </table>
              </td>
          </tr>
          <tr style="width: 100%">
              <td colspan="2" width="100%" align="center">Thank you / Благодаря</td>
          </tr>
      </table>

<!--    <div class="invoice overflow-auto">-->
<!--      <div style="min-width: 600px">-->
<!--        <header>-->
<!--          <div class="row">-->
<!--            <div class="col">-->
<!--              <img src="@/assets/img/logo.png" height="100" alt="Econik Logo">-->
<!--            </div>-->
<!--            <div class="col company-details">-->
<!--              <h2 class="name"><a target="_blank" :href="business.website">{{ business.name }}</a></h2>-->
<!--              <div>{{ business.address }}</div>-->
<!--              <div><a :href="`mailto:${business.email}`">{{ business.email }}</a></div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </header>-->
<!--        <main>-->
<!--          <div class="row contacts">-->
<!--            <div class="col invoice-to">-->
<!--              <div class="text-gray-light">TO / ЗА:</div>-->
<!--              <h2 class="to">{{ invoice.client.name ?? '' }}</h2>-->
<!--              <div class="address">-->
<!--                <i v-if="invoice.client.address || invoice.client.area || invoice.client.area || invoice.client.country" class="fas fa-map-marker-alt fa-xs mr-1"></i>-->
<!--                {{ invoice.client.address ? `${invoice.client.address}` : '' }}-->
<!--                {{ invoice.client.area ? `, ${invoice.client.area}` : '' }}-->
<!--                {{ invoice.client.city ? `, ${invoice.client.city}` : '' }}-->
<!--                {{ invoice.client.country ? `, ${invoice.client.country}` : '' }}-->
<!--              </div>-->
<!--              <div class="address">-->
<!--                <i v-if="invoice.client.afm || invoice.client.company" class="fas fa-building fa-xs mr-1"></i>-->
<!--                {{ invoice.client.company ? `${invoice.client.company}` : '' }}-->
<!--                {{ invoice.client.afm ? `, ${invoice.client.afm}` : '' }}-->
<!--              </div>-->
<!--              <div class="address">-->
<!--                <i v-if="invoice.client.phone" class="fas fa-phone-alt fa-xs mr-1"></i>-->
<!--                {{ invoice.client.phone ? `${invoice.client.phone}` : '' }}-->
<!--              </div>-->
<!--              <div class="email">-->
<!--                <i v-if="invoice.client.email" class="fas fa-envelope fa-xs mr-1"></i>-->
<!--                <a v-if="invoice.client.email" :href="`mailto:${invoice.client.email}`">{{ invoice.client.email }}</a>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col invoice-details">-->
<!--              <h1 class="invoice-id"> Invoice / Фактура: {{ invoice.series }} {{ invoice.number }}</h1>-->
<!--              <div class="date">Date / Дата {{ new Date(invoice.date).toLocaleDateString('el-GR', { day: 'numeric', month: 'numeric', year: 'numeric' }) }}</div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <table>-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th>#</th>-->
<!--              <th class="text-left">Description / Описание</th>-->
<!--              <th class="text-right">Price / Единична Цена</th>-->
<!--              <th class="text-right">Quantity / Количество</th>-->
<!--              <th class="text-right">Total / Общо</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <tr v-for="(i, index) in invoice.products" :key="index">-->
<!--              <td class="no">{{ (index + 1).toLocaleString('el-GR', {minimumIntegerDigits: 2}) }}</td>-->
<!--              <td class="text-left">-->
<!--                <h3><a target="_blank" href="javascript:void(0);">{{ i.name }}</a></h3>-->
<!--              </td>-->
<!--              <td class="unit">-->
<!--                  {{ i.price ? parseFloat(i.price).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) : '' }}<br>-->
<!--                  {{ i.price ? (parseFloat(i.price) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '' }}-->
<!--              </td>-->
<!--              <td class="qty">{{ i.quantity }} {{ i.unit }}</td>-->
<!--              <td class="total">-->
<!--                {{ i.subtotal ? parseFloat(i.subtotal).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) : '0.00 €' }}<br>-->
<!--                {{ i.subtotal ? (parseFloat(i.subtotal) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '0.00 €' }}-->
<!--              </td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--            <tfoot>-->
<!--            <tr>-->
<!--              <td colspan="2"></td>-->
<!--              <td colspan="2">Subtotal / Междинна сума</td>-->
<!--              <td>{{ invoice.subTotal ? parseFloat(invoice.subTotal).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) + ' / ' + (parseFloat(invoice.subTotal) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '0.00 €' }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td colspan="2"></td>-->
<!--              <td colspan="2">Sales Tax / ДДС {{ invoice.vatValue }}%</td>-->
<!--              <td>{{ invoice.vatTotal ? parseFloat(invoice.vatTotal).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) + ' / ' + (parseFloat(invoice.vatTotal) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '0.00 €' }}</td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td colspan="2"></td>-->
<!--              <td colspan="2">Total / Сума</td>-->
<!--              <td>{{ invoice.total ? parseFloat(invoice.total).toLocaleString('el-GR', { style: 'currency', currency: 'EUR' }) + ' / ' + (parseFloat(invoice.total) * levExchangeRate).toLocaleString('bg-BG', { style: 'currency', currency: 'BGN' }) : '0.00 €' }}</td>-->
<!--            </tr>-->
<!--            </tfoot>-->
<!--          </table>-->
<!--          <div class="thanks">Thank you / Благодаря </div>-->

<!--        </main>-->
<!--      </div>-->
<!--      &lt;!&ndash;DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom&ndash;&gt;-->
<!--      <div></div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "InvoiceComponent",
  props: ['invoice'],
  data() {
    return {
      business: {
        name: process.env.VUE_APP_BUSINESS_NAME,
        address: process.env.VUE_APP_BUSINESS_ADDRESS,
        email: process.env.VUE_APP_BUSINESS_EMAIL,
        website: process.env.VUE_APP_BUSINESS_WEBSITE,
      },
      levExchangeRate: 1.96,
    }
  },
  methods: {
    numberTo2decimals(num) {
      return parseFloat(Math.round(num * 100) / 100).toFixed(2);
    },
  }
}
</script>

<style scoped>
@import '@/assets/css/invoice.css';
</style>
<template>
    <AuthLayout title="Δημιουργία Νέου Κωδικού Πρόσβασης">
        <form>
            <div class="form-group">
                <label for="input-password">Νέος Κωδικός Πρόσβασης</label>
                <input class="form-control" id="input-password" type="password"
                       placeholder="Εισάγετε νέο κωδικό πρόσβασης" v-model="password" @keyup.enter="resetPassword">
            </div>
            <div class="form-group">
                <label for="input-c-password">Επανάληψη Κωδικού Πρόσβασης</label>
                <input class="form-control" id="input-c-password" type="password"
                       placeholder="Επαναλάβετε τον νέο κωδικό πρόσβασης" v-model="password_confirmation"
                       @keyup.enter="resetPassword">
            </div>
            <button type="button" class="btn btn-primary btn-block" @click="resetPassword">Αλλαγή Κωδικού</button>
        </form>
    </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import axios from "axios";

export default {
    name: "ResetPasswordView",
    components: {AuthLayout},
    beforeRouteEnter(to, from, next) {

        if (!to.params.token || to.params.token.length !== 64 || !to.query.email) {
            next({name: "login"});
            return;
        }

        const data = new FormData();
        data.append('email', to.query.email);
        data.append('token', to.params.token);

        axios.post(`${process.env.VUE_APP_API_URL}/api/validate-reset-password-page`, data, {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        }).then((response) => {
            if (response.data.success) {
                next();
                return;
            }
            next({name: "login"});
        }).catch(() => {
            next({name: "login"});
        });

    },
    data() {
        return {
            password: '',
            password_confirmation: '',
        }
    },
    methods: {
        resetPassword() {
            if (this.password !== this.password_confirmation) {

                this.$toast.error('Οι κωδικοί πρόσβασης δεν είναι ίδιοι');
                return;
            }

            const data = new FormData();
            data.append('token', this.$route.params.token);
            data.append('email', this.$route.query.email);
            data.append('password', this.password);
            data.append('password_confirmation', this.password_confirmation);

            axios.post(`${process.env.VUE_APP_API_URL}/api/reset-password`, data, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            }).then((response) => {
                this.$toast.success(response.data.message);
                this.$router.push({name: "login"});
            }).catch((error) => {
                if (error.response.status === 400) {
                    this.$toast.error('Κάτι πήγε στραβά')
                } else if (error.response.status === 422) {
                    this.$toast.error(error.response.responseJSON.errors[Object.keys(error.response.responseJSON.errors)[0]][0])
                } else if (error.response.status === 429) {
                    this.$toast.error('Υπερβολικά πολλά αιτήματα! Προσπαθήστε μετά από μερικά λεπτά!')
                }
            });
        }
    }
}
</script>

<style scoped>

</style>
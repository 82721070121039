<template>
    <admin-layout :active-menu="page.active" :page-title="page.title" :page-button="page.button">

        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Δημιουργία Νέου Παραστατικού</h6>
            </div>
            <div class="card-body">
                <form id="invoice-form">
                    <div class="row">
                        <div class="col">
                            <label for="client-name">Πελάτης</label>
                            <vue-select label="name" value="id" placeholder="Αναζήτηση Πελατών"
                                        :options="invoice.clients"
                                        :filterable="false"
                                        @search="filterClients"
                                        @option:selected="selectClient($event)"
                                        :remote-search-delay="500"
                                        :remote="true"
                                        :clearable="false"
                            ></vue-select>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            <label for="invoice-type">Είδος Παραστατικού</label>
                            <vue-select v-model="invoiceType" label="customLabel" placeholder="Επιλέξτε Είδος"
                                        :value="invoiceType?.id"
                                        :reduce="option => option.id"
                                        :option-label="option => option.name"
                                        :options="invoiceTypes"
                                        :searchable="false"
                                        :filterable="false"
                                        @option:selected="setInvoiceNumber($event)"
                                        :clearable="false"
                            ></vue-select>
                        </div>
                        <div class="col">
                            <label for="invoice-type">Φ.Π.Α.</label>

                            <vue-select v-model="countryVat" placeholder="Επιλέξτε Φ.Π.Α."
                                        :value="countryVat?.id"
                                        :reduce="option => option.id"
                                        :option-label="option => option.name"
                                        :options="countriesVats"
                                        :searchable="false"
                                        :filterable="false"
                                        :disabled="!invoiceType"
                                        @option:selected="setCountryVat($event)"
                                        :clearable="false"
                            ></vue-select>

                        </div>
                        <div class="col">
                            <label>Ημ/νία Παραστατικού</label><br>
                            <datepicker placeholder="Ημερομηνία" :class="'form-control'" :locale="el"
                                        :lower-limit="minDateAvailable" :upper-limit="maxDateAvailable"
                                        v-model="invoice.date" :disabled="!countryVat"></datepicker>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col">
                            Προϊόντα
                        </div>
                    </div>

                    <div class="row mt-2" v-for="(item, index) in invoice.products" :key="index">
                        <div class="col-3">
                            <vue-select v-model="invoice.products[index].code"
                                        placeholder="Κωδικός Προϊόντος"
                                        label="code" value="id"
                                        :options="productCodesList"
                                        :disabled="!countryVat"
                                        :filterable="false"
                                        @search="filterProductsByCode" @option:selected="selectProduct($event, index)"
                                        :clearable="false"
                            ></vue-select>
                        </div>
                        <div class="col-4">
                            <vue-select v-model="invoice.products[index].name"
                                        placeholder="Τίτλος Προϊόντος"
                                        label="name" value="id"
                                        :options="productNamesList"
                                        :disabled="!countryVat"
                                        :filterable="false"
                                        @search="filterProductsByDescription"
                                        @option:selected="selectProduct($event, index)"
                                        :clearable="false"
                            ></vue-select>
                        </div>
                        <div class="col-3">
                            <div class="input-group mb-3">
                                <span v-if="width >= 1400" class="input-group-text" id="basic-addon1">Ποσότητα</span>
                                <span v-else class="input-group-text" id="basic-addon1"><i
                                        class="fas fa-th-list"></i></span>
                                <input type="number" :id="'input-product-quantity-' + index" class="form-control"
                                       v-model="item.quantity"
                                       :disabled="!invoice.products[index].code && !invoice.products[index].name"
                                       @keyup="changeProductQuantity($event, item.quantity, item.stock, index)"
                                       @change="changeProductQuantity($event, item.quantity, item.quantity, index)"
                                       min="1" :max="item.stock">
                            </div>
                        </div>
                        <div class="col-2">
                            <button type="button" class="btn btn-outline-secondary" :id="'delete-row-btn-' + index"
                                    @click="removeProduct(index)" :data-index="index" title="Διαγραφή"
                                    v-if="invoice.products.length > 1"><i class="fas fa-trash-alt"></i>
                            </button>
                            <button type="button" class="btn btn-outline-secondary ml-2" id="add-row-btn"
                                    @click="addProduct()" title="Προσθήκη"
                                    v-if="invoice.products.length === (index+1) && invoice.products.length < 10 && item.id > 0">
                                <i class="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>

                </form>
            </div>
        </div>

        <div class="row-12">
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">Προεπισκόπιση Παραστατικού</h6>
                </div>
                <div class="card-body">
                    <InvoiceComponent :invoice="invoice"/>
                </div>
            </div>
        </div>

    </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import catchCrudErrors from "@/library/ErrorHandling";

import VueSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import Datepicker from 'vue3-datepicker';
import {el} from "date-fns/locale";
import {storeElement} from "@/library/CRUD";
import Swal from "sweetalert2";
import InvoiceComponent from "@/components/InvoiceComponent.vue";
import axios from "axios";

export default {
    name: "InvoiceManagement",
    computed: {
        el() {
            return el
        }
    },
    created() {
        window.create = this.create;
        window.navigateToInvoices = this.navigateToInvoices;
    },
    components: {InvoiceComponent, AdminLayout, Datepicker, VueSelect},
    data() {
        return {
            page: {
                active: "invoices",
                title: '<i class="fas fa-fw fa-file-invoice mr-2"></i>Δημιουργία Νέου Παραστατικού',
                button: `<button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onclick="navigateToInvoices()"><i class="fas fa-times fa-sm text-white-50"></i> Ακύρωση</button>
                    <button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onclick="create()"><i class="fas fa-save fa-sm text-white-50"></i> Αποθήκευση</button>`
            },
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
            minDateAvailable: null,
            maxDateAvailable: null,
            invoiceTypes: [],
            invoiceType: null,
            countriesVats: [],
            countryVat: null,
            invoice: {
                number: '',
                series: '',
                date: new Date(),
                clients: [],
                client: {},
                products: [
                    {
                        id: 0,
                        title: null,
                        name: null,
                        price: '',
                        subtotal: '',
                        quantity: 1,
                        unit: '',
                    }
                ],
                vatValue: 0.00,
                subTotal: 0.00,
                vatTotal: 0.00,
                total: 0.00,
            },
            productCodesList: [],
            productNamesList: []
        }
    },
    methods: {
        setInvoiceNumber(event) {
            this.minDateAvailable = new Date(event.min_date);
            this.maxDateAvailable = new Date(event.max_date);
            this.invoice.date = new Date(event.min_date);
            this.invoice.series = event.series;
            this.invoice.number = (event.last_num + 1);
            this.invoiceType = event.id;
        },
        addProduct() {
            if (parseInt(this.invoice.products[0].id) === 0) {
                this.invoice.products = [];
            }
            this.invoice.products.push({id: 0, quantity: 1})
        },
        removeProduct(position) {
            this.invoice.products.splice(position, 1);
            this.calculateInvoiceValues();
        },
        calculateInvoiceValues() {
            this.invoice.subTotal = 0;
            this.invoice.products.forEach((item) => {
                this.invoice.subTotal += (item.price * item.quantity);
            })
            this.invoice.subTotal = this.numberTo2decimals(parseFloat(this.invoice.subTotal));
            this.invoice.vatTotal = 0;
            this.invoice.vatTotal = this.numberTo2decimals(this.invoice.subTotal * this.invoice.vatValue / 100);
            this.invoice.total = 0;
            this.invoice.total = this.numberTo2decimals(parseFloat(this.invoice.subTotal) + parseFloat(this.invoice.vatTotal));

            this.invoice.products.forEach((product) => {
                product.subtotal = parseFloat(this.numberTo2decimals((product.quantity * parseFloat(product.price))))
            });
        },
        changeProductQuantity(e, value, max, index) {
            if (value > max) {
                this.invoice.products[index].quantity = max;
                return;
            }
            if (value <= 0) {
                this.invoice.products[index].quantity = 1;
                return;
            }
            this.calculateInvoiceValues();
        },
        numberTo2decimals(num) {
            return parseFloat(Math.round(num * 100) / 100).toFixed(2);
        },
        filterProductsByCode(search, loading) {
            if (search) {
                this.productCodesList = [];
                this.productNamesList = [];

                let selected = [];
                this.invoice.products.forEach((item) => {
                    selected.push(item.id)
                });

                loading(true);

                axios.post(`${process.env.VUE_APP_API_URL}/api/products/filterByCode`, {
                    code: search,
                    selected: selected
                }, {
                    headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
                }).then(response => {
                    response.data.results.forEach((item) => {
                        this.productCodesList.push({
                            'id': item.id,
                            'title': item.title,
                            'code': item.code,
                            'name': item.name,
                            'price': item.price,
                            'subtotal': 0,
                            'quantity': 1,
                            'unit': item.unit,
                            'stock': item.stock
                        });
                    });
                    loading(false);
                }).catch(
                    error => catchCrudErrors(error.response)
                );
            }
        },
        filterProductsByDescription(search, loading) {
            if (search) {
                this.productCodesList = [];
                this.productNamesList = [];

                let selected = [];
                this.invoice.products.forEach((item) => {
                    selected.push(item.id)
                });

                loading(true);

                axios.post(`${process.env.VUE_APP_API_URL}/api/products/filterByDescription`, {
                    description: search,
                    selected: selected
                }, {
                    headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
                }).then(response => {
                    this.productCodesList = [];
                    this.productNamesList = [];
                    response.data.results.forEach((item) => {
                        this.productNamesList.push({
                            'value': item.id,
                            'label': item.code,
                            'id': item.id,
                            'title': item.title,
                            'code': item.code,
                            'name': item.name,
                            'price': item.price,
                            'subtotal': 0,
                            'quantity': 1,
                            'unit': item.unit,
                            'stock': item.stock
                        });
                    });
                    loading(false);
                }).catch(
                    error => catchCrudErrors(error.response)
                );
            }
        },
        filterClients(search, loading) {
            if (search) {
                this.invoice.clients = [];

                axios.post(`${process.env.VUE_APP_API_URL}/api/clients/search`, {value: search}, {
                    headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                }).then(response => {
                    this.invoice.clients = response.data.results;
                    loading(false);
                }).catch(
                    error => catchCrudErrors(error.response)
                );
            }
        },
        selectProduct(object, index) {
            this.invoice.products[index] = object;
            this.calculateInvoiceValues();
            this.productCodesList = [];
            this.productNamesList = [];
        },
        selectClient(object) {
            this.invoice.client = object;
            this.invoice.clients = [];

            let successHandler = (response) => {

                this.invoiceTypes = [];
                response.forEach((item) => {
                    if (!object.afm && item.type !== 1) {
                        return;
                    }
                    this.invoiceTypes.push({
                        'id': item.id,
                        'label': `${item.description} (Σειρά ${item.series})`,
                        'customLabel': `${item.description} (Σειρά ${item.series})`,
                        'description': item.description,
                        'last_num': item.last_num,
                        'series': item.series,
                        'type': item.type,
                        'min_date': item.min_date,
                        'max_date': item.max_date,
                    });
                });
                if (this.invoiceTypes.length) {
                    this.invoiceType = 1;
                    this.setInvoiceNumber(this.invoiceTypes[0]);
                }

            }

            axios.get(`${process.env.VUE_APP_API_URL}/api/invoice-types/get`, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            }).then(
                response => successHandler(response.data)
            ).catch(
                error => catchCrudErrors(error.response)
            );

            axios.get(`${process.env.VUE_APP_API_URL}/api/countries-vats/get`, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            }).then(response => {
                this.countriesVats = response.data;
                if (response.data.length) {
                    const clientCountry = this.invoice.client.country_id;
                    this.countriesVats.forEach((v, i) => {
                        if (v.country_id === clientCountry) {
                            if (v.type_id === 1 && !this.invoice.client.afm.length > 0) {
                                this.countryVat = response.data[i].id;
                                this.invoice.vatValue = response.data[i].value;
                                return;
                            }
                            if (v.type_id === 2 && this.invoice.client.afm) {
                                this.countryVat = response.data[i].id;
                                this.invoice.vatValue = response.data[i].value;
                                return;
                            }
                            this.countryVat = response.data[0].id;
                            this.invoice.vatValue = response.data[0].value;
                        }
                    })
                }
            }).catch(
                error => catchCrudErrors(error.response)
            );
        },
        setCountryVat(event) {
            this.invoice.vatValue = event.value;
            this.calculateInvoiceValues();
        },
        validatedData() {
            if (!(this.invoice.client.id > 0)) {
                this.$toast.error('Ο Πελάτης δεν είναι έγκυρος!');
                return false;
            }

            if (!(this.invoiceType > 0)) {
                this.$toast.error('Το είδος παραστατικού δεν είναι έγκυρο!');
                return false;
            }

            if (!(this.countryVat > 0)) {
                this.$toast.error('Ο Φ.Π.Α. δεν είναι έγκυρος!');
                return false;
            }

            if (!(this.invoice.products[0].id > 0)) {
                this.$toast.error('Δεν έχετε προσθέσει προϊόντα!');
                return false;
            }

            return true;

        },
        create() {
            if (!this.validatedData()) {
                return;
            }
            const formData = new FormData();

            formData.append('client', this.invoice.client.id);
            formData.append('invoice-type', this.invoiceType);
            formData.append('date', new Date(Date.parse(this.invoice.date)).toISOString());
            this.invoice.products.forEach((product, index) => {
                formData.append(`products[${index}][id]`, product.id);
                formData.append(`products[${index}][title]`, product.title);
                formData.append(`products[${index}][unit]`, product.unit);
                formData.append(`products[${index}][quantity]`, product.quantity);
                formData.append(`products[${index}][price]`, product.price);
                formData.append(`products[${index}][subtotal]`, product.subtotal);
            });
            formData.append('vat', this.countryVat);
            formData.append('number', this.invoice.number);
            formData.append('sub-total', this.invoice.subTotal);
            formData.append('vat-total', this.invoice.vatTotal);
            formData.append('gen-total', this.invoice.total);

            const successHandler = (response) => {
                Swal.fire({
                    title: 'Επιτυχία',
                    text: response.message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    this.$router.push({'name': 'invoices'});
                });
            }
            storeElement(formData, `${process.env.VUE_APP_API_URL}/api/invoices/store`, false, false, successHandler);
        },
        navigateToInvoices() {
            this.$router.push({name: 'invoices'});
        }
    }
}
</script>

<style scoped>
@import '@/assets/css/invoice.css';
</style>
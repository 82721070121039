<template>
    <AuthLayout title="Σύνδεση στο CRM">
        <form>
            <div class="form-group">
                <label for="input-email">Διεύθυνση Email</label>
                <input class="form-control" id="input-email" type="email" placeholder="Εισάγετε το email σας"
                       v-model="email" @keyup.enter="login">
            </div>
            <div class="form-group">
                <label for="input-password">Κωδικός Πρόσβασης</label>
                <input class="form-control" id="input-password" type="password" placeholder="Εισάγετε τον κωδικό σας"
                       v-model="password" @keyup.enter="login">
            </div>
            <button type="button" class="btn btn-primary btn-block" @click="login">Σύνδεση</button>
        </form>
        <div class="text-center">
            <router-link :to="{name: 'forgot-password'}" class="d-block small mt-3">Ξεχάσατε τον κωδικό σας;
            </router-link>
        </div>
    </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";

import axios from "axios";

export default {
    name: "LoginComponent",
    components: {AuthLayout},
    beforeRouteEnter(to, from, next) {
        if (localStorage.getItem('token') !== null) {
            next({name: "dashboard"})
        } else {
            next()
        }
    },
    data() {
        return {
            email: "",
            password: ""
        }
    },
    methods: {
        login() {
            let data = new FormData();
            data.append('email', this.email);
            data.append('password', this.password);

            axios.post(`${process.env.VUE_APP_API_URL}/api/login`, data, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                processData: false,
                contentType: false,
            }).then(response => {
                localStorage.setItem('token', response.data.data.token)
                localStorage.setItem('username', response.data.data.name)
                localStorage.setItem('ip', response.data.data.ip)
                localStorage.setItem('id', response.data.data.id)
                localStorage.setItem('loginDate', response.data.data.lastLogin)
                this.$router.push({name: "dashboard"});
            }).catch(error => {
                if (error.response.status === 422) {
                    this.$toast.error(error.response.data.errors[Object.keys(error.response.data.errors)[0]][0])
                } else if (error.response.status === 401) {
                    this.$toast.error('Σφάλμα σύνδεσης')
                } else if (error.response.status === 429) {
                    this.$toast.error('Υπερβολικά πολλά αιτήματα! Προσπαθήστε ξανά μετά από λίγα λεπτά!')
                }
            });

        }
    }

}
</script>

<style scoped>

</style>
<template>
    <admin-layout :active-menu="page.active" :page-title="page.title" :page-button="this.page.button">
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Πίνακας Διαχείρισης Προϊόντων</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">

                    <table class="table table-bordered" id="products-datatable">
                        <thead>
                        <tr>
                            <th><i class="fas fa-shopping-cart mr-2"></i>Τύπος</th>
                            <th><i class="fas fa-hashtag mr-2"></i>Κωδικός</th>
                            <th><i class="fas fa-comments mr-2"></i>Τίτλος</th>
                            <th><i class="fas fa-euro-sign mr-2"></i>Τιμή</th>
                            <th><i class="fas fa-th-list mr-2"></i>Απόθεμα</th>
                            <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
                        </tr>
                        </thead>
                        <tfoot>
                        <tr>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
                            <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
                        </tr>
                        </tfoot>
                        <tbody>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Products Modal-->
        <modal-component id="product-modal" :title="modals.product.title" :button="modals.product.button"
                         :action="modals.product.action">
            <form id="product-form">
                <div class="row">
                    <div class="col" v-if="inputs.id === 0">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-shopping-bag"></i></span>
                            </div>
                            <select class="form-control border" id="input-type" name="input-type" v-model="inputs.type">
                                <option value="1" selected>Απλό Προϊόν</option>
                                <option value="2">Πακέτο Προϊόντων</option>
                            </select>
                        </div>
                    </div>
                    <div class="col">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-hashtag"></i></span>
                            </div>
                            <input type="text" class="form-control"
                                   :placeholder="parseInt(inputs.type) === 1 ? 'Κωδικός Προϊόντος' : 'Κωδικός Πακέτου'"
                                   id="input-code" name="input-code" v-model="inputs.code">
                        </div>
                    </div>
                    <div class="col">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-comments"></i></span>
                            </div>
                            <input type="text" class="form-control"
                                   :placeholder="parseInt(inputs.type) === 1 ? 'Τίτλος Προϊόντος' : 'Τίτλος Πακέτου'"
                                   id="input-title" name="input-title" v-model="inputs.title">
                        </div>

                    </div>
                </div>
                <div class="row" v-if="parseInt(inputs.type) === 1">

                    <div class="col-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-barcode"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Barcode Προϊόντος" id="input-barcode"
                                   name="input-barcode" v-model="inputs.barcode">
                        </div>
                    </div>
                </div>

                <div class="row" v-if="parseInt(inputs.type) === 2">
                    <div class="col-6">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-barcode"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Barcode Πακέτου" id="input-barcode"
                                   name="input-barcode" v-model="inputs.barcode">
                        </div>
                    </div>
                    <div class="col-6">

                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-euro-sign"></i></span>
                            </div>
                            <input type="number" step="0.01" class="form-control" placeholder="Τιμή" id="input-price"
                                   name="input-price" v-model="inputs.price">
                        </div>

                    </div>
                </div>
                <hr>

                <div class="row" :style="(parseInt(inputs.type) === 1 ? 'display: none' : 'display: flex')">
                    <div class="col-6">
                        <select class="form-control" id="products-dropdown" multiple></select>
                    </div>
                    <div class="col-6 float-right">
                        <table class="table table-bordered" id="modal-products-datatable">
                            <thead>
                            <tr>
                                <th><i class="fas fa-shopping-cart mr-2"></i>Προϊόν</th>
                                <th><i class="fas fa-th-list mr-2"></i>Τεμάχια Πακέτου</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(product, index) in productsData" :key="index">
                                <td>{{ product.name }}</td>
                                <td><input class="form-control" type="number"
                                           :name="'input-product-quantity['+product.id+']'" :value="product.quantity">
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div v-if="parseInt(inputs.type) === 1">
                    <div class="row">
                        <div class="col-6">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-euro-sign"></i></span>
                                </div>
                                <input type="number" step="0.01" class="form-control" placeholder="Τιμή"
                                       id="input-price" name="input-price" v-model="inputs.price">
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-weight"></i></span>
                                </div>
                                <input type="text" class="form-control" placeholder="Μονάδα Μέτρησης" id="input-unit"
                                       name="input-unit" v-model="inputs.unit">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-4">

                            <label for="input-stock">Απόθεμα</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-list"></i></span>
                                </div>
                                <input type="number" class="form-control" placeholder="Απόθεμα" id="input-stock"
                                       name="input-stock" v-model="inputs.stock">
                            </div>

                        </div>

                        <div class="col-4">

                            <label for="input-stock-alert">Ειδοποίηση Αποθέματος</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-bell"></i></span>
                                </div>
                                <input type="number" class="form-control" placeholder="Ειδοποίηση Αποθέματος"
                                       id="input-stock-alert" name="input-stock-alert" v-model="inputs.stock_alert">
                            </div>

                        </div>

                        <div class="col-4">

                            <label for="input-stock-pos">Θέση στην αποθήκη</label>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-list-alt"></i></span>
                                </div>
                                <input type="text" class="form-control" placeholder="Θέση" id="input-stock-pos"
                                       name="input-stock-pos" v-model="inputs.stock_pos">
                            </div>

                        </div>
                    </div>
                </div>


            </form>
        </modal-component>

    </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import guard from "@/library/check-token";
import ModalComponent from "@/components/ModalComponent.vue";
import $ from "jquery";
import datatableLanguages from "@/library/DatatableLanguages";
import catchDatatableErrors from "@/library/error-handling/Datatable";
import catchCrudErrors from "@/library/ErrorHandling";
import Choices from "choices.js";
import Pusher from "pusher-js"
import {destroyElement, editElement, prepareElement, storeElement, updateElement} from "@/library/CRUD";

export default {
    name: "ProductsView",
    components: {AdminLayout, ModalComponent},
    beforeRouteEnter(to, from, next) {
        guard(to, from, next)
    },
    created() {
        window.prepare = this.prepare;
        window.edit = this.edit;
        window.destroy = this.destroy;
        window.manageStock = this.manageStock;
        window.updateStock = this.updateStock;
    },
    data() {
        let pusher = new Pusher('855b087afc4f5f1d9087', {
            cluster: 'eu',
            authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
            auth: {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
            }
        });
        return {
            channel: pusher.subscribe('private-product'),
            page: {
                active: "products",
                title: '<i class="fas fa-fw fa-shopping-cart mr-2"></i>Προϊόντα',
                button: '<button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onclick="prepare()"><i class="fas fa-plus fa-sm text-white-50"></i> Προσθήκη Νέου Προϊόντος</button>'
            },
            table: null,
            form: "#product-form",
            inputs: {
                id: 0,
                type: 1,
                code: "",
                title: "",
                barcode: "",

                products: [],

                price: 0,
                unit: "",

                stock: 0,
                stock_pos: "",
                stock_alert: 0
            },
            productsDropdown: null,
            modals: {
                product: {
                    title: null,
                    button: null,
                    action: null
                },
                stock: {
                    title: '<i class="fas fa-fw fa-tools mr-2"></i>Διαχείριση Αποθήκης',
                    button: '<i class="fas fa-save mr-2"></i> Αποθήκευση',
                }
            },
            productsData: []
        }
    },
    mounted() {
        this.channel.bind('product-inserted', () => this.table.ajax.reload(null, false));

        this.table = $("#products-datatable").DataTable({
            initComplete: function () {
                this.api()
                    .columns()
                    .every(function () {
                        const that = this;

                        $('input', this.footer()).on('keyup change clear', function () {
                            if (that.search() !== this.value) {
                                that.search(this.value).draw();
                            }
                        });
                    });
            },
            columnDefs: [
                {data: 'type', render: (type) => this.getProductTypeIcon(type), orderable: true, className: 'dt-center', targets: 0, width: '10%'},
                {data: 'code', orderable: true, className: 'dt-center', targets: 1, width: 'auto'},
                {data: 'title', orderable: true, className: 'dt-center', targets: 2, width: 'auto'},
                {data: 'price', orderable: true, className: 'dt-center', targets: 3, width: '15%'},
                {data: null, name: 'stock', render: (data) => this.getStockNumber(data), orderable: true, className: 'dt-center', targets: 4, width: '10%'},
                {data: null, render: (data) => this.getActionButtons(data.id), orderable: false, className: 'dt-center', targets: 5, width: '10%'}
            ],
            language: datatableLanguages,
            autoWidth: false,
            processing: true,
            serverSide: true,
            ajax: {
                type: 'POST',
                url: `${process.env.VUE_APP_API_URL}/api/products/data`,
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                error: (error) => catchDatatableErrors(error)
            },
        })

        this.productsDropdown = new Choices('#products-dropdown', {
            loadingText: "Φόρτωση...",
            searchPlaceholderValue: "Αναζήτηση Προϊόντων",
            noChoicesText: "Δεν βρέθηκαν προϊόντα με αυτά τα κριτήρια αναζήτησης",
            noResultsText: "Δεν βρέθηκαν προϊόντα",
            itemSelectText: "Προσθήκη",
            searchResultLimit: 5,
            removeItemButton: true,
            duplicateItemsAllowed: false,
            placeholderValue: "Αναζήτηση Προϊόντων"
        });

        // On search event listener
        document.getElementById('products-dropdown').addEventListener(
            'search',
            (event) => {
                if (event.detail.value.length >= 3) {
                    $.ajax({
                        type: 'POST',
                        url: `${process.env.VUE_APP_API_URL}/api/products/filter`,
                        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                        data: {value: event.detail.value, selected: this.productsDropdown.getValue()},
                        success: (response) => {
                            this.productsDropdown.clearChoices();
                            this.productsDropdown.setChoices(response, 'value', 'label', false);
                        },
                        error: (error) => catchCrudErrors(error)
                    });
                }
            },
            false,
        );

        // On add item event listener
        document.getElementById('products-dropdown').addEventListener(
            'addItem',
            (event) => {
                this.productsData.push({id: event.detail.value, name: event.detail.label, quantity: 0})
            },
            false,
        );

        // On remove item event listener
        document.getElementById('products-dropdown').addEventListener(
            'removeItem',
            (event) => {
                this.productsData.forEach((item, index) => {
                    if (item.name === event.detail.label) {
                        this.productsData.splice(index, 1);
                    }
                })
            },
            false,
        );

    },
    methods: {
        getActionButtons(id) {
            const viewButton = `<button class="btn btn-outline-warning btn-sm mr-1" id="edit-product-${id}" onclick="edit(${id})"><i class="fas fa-edit"></i></button>`;
            const editButton = `<button class="btn btn-outline-danger btn-sm ml-1" id="destroy-product-${id}" onclick="destroy(${id})"><i class="fas fa-trash"></i></button>`;
            return viewButton + editButton;
        },
        getProductTypeIcon(type) {
            return type === 1 ? '<i class="fas fa-file fa-lg" title="Απλό Προϊόν">' : '<i class="fas fa-box fa-lg" title="Πακέτο Προϊόντων">';
        },
        getStockNumber(data) {
            const color = data.stock === 0 ? 'danger' : (data.stock <= data.stock_alert ? 'warning' : 'success');
            return `<span class="badge badge-pill badge-${color}">${data.stock}</span>`;
        },
        prepare() {
            let success = (response) => {
                this.modals.product = response
                this.modals.product.action = this.store;
                this.productsDropdown.clearInput();
                this.productsDropdown.clearStore();
                this.productsData = [];
                this.resetData();
                $("#product-modal").modal("show");
            }
            prepareElement(`${process.env.VUE_APP_API_URL}/api/products/prepare`, success);
        },
        store() {
            // Serialize the form
            $(this.form).serialize();
            let form = $(this.form);
            let formData = new FormData(form[0])

            this.productsDropdown.getValue().forEach((item, index) => {
                formData.append('input-products[' + index + ']', item.value);
            })

            storeElement(formData, `${process.env.VUE_APP_API_URL}/api/products/store`, this.table, $("#product-modal"))
        },
        edit(id) {
            let success = (response) => {
                this.inputs = response.data;
                this.modals.product = response.modal;
                this.productsDropdown.clearInput();
                this.productsDropdown.clearStore();

                this.productsDropdown.setChoices(response.data.products, 'product_id', 'title', false);

                this.productsData = [];
                response.data.products.forEach((item, index) => {
                    this.productsDropdown.setChoiceByValue(item.product_id)
                    this.productsData[index] = {id: item.product_id, name: item.title, quantity: item.quantity};
                });
                this.modals.product.action = this.update;
                $("#product-modal").modal("show");
            }
            editElement(id, `${process.env.VUE_APP_API_URL}/api/products/edit`, success);
        },
        update() {
            // Serialize the form
            $(this.form).serialize();
            let form = $(this.form);
            let formData = new FormData(form[0])

            // Append the ID to the form data object
            formData.append('input-id', this.inputs.id);
            formData.append('input-type', this.inputs.type);

            this.productsDropdown.getValue().forEach((item, index) => {
                formData.append('input-products[' + index + ']', item.value);
            })

            updateElement(formData, `${process.env.VUE_APP_API_URL}/api/products/update`, this.table, $("#product-modal"));
        },
        destroy(id) {
            destroyElement(id, `${process.env.VUE_APP_API_URL}/api/products/destroy`, 'Αυτή η ενέργεια θα διαγράψει μόνιμα το προϊόν!', this.table);
        },
        resetData() {
            this.inputs = {
                id: 0,
                type: 1,
                code: "",
                title: "",
                barcode: "",

                products: [],

                price: 0,
                unit: "",

                stock: 0,
                stock_pos: "",
                stock_alert: 0
            }
            // this.inputs = {
            //   id: 0,
            //   type: 1,
            //   code: "22123",
            //   title: "yee",
            //   barcode: "1232332222222",
            //
            //   products: [],
            //
            //   price: 20,
            //   unit: "qwe",
            //
            //   stock: 12,
            //   stock_pos: "12b",
            //   stock_alert: 11
            // }
        }
    }
}
</script>

<style scoped>
@import "choices.js/public/assets/styles/choices.min.css";
</style>
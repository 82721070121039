<template>
    <admin-layout :active-menu="page.active" :page-title="page.title" :page-button="page.button">
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Πίνακας Διαχειριστών</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="admins-datatable">
                        <thead>
                        <tr>
                            <th><i class="fas fa-comments mr-2"></i>Ονοματεπώνυμο</th>
                            <th><i class="fas fa-at mr-2"></i>Email</th>
                            <th><i class="fas fa-calendar mr-2"></i>Ημερομηνία Εγγραφής</th>
                            <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
                        </tr>
                        </thead>
                        <tfoot>
                        <tr>
                            <th><i class="fas fa-comments mr-2"></i>Ονοματεπώνυμο</th>
                            <th><i class="fas fa-at mr-2"></i>Email</th>
                            <th><i class="fas fa-calendar mr-2"></i>Ημερομηνία Εγγραφής</th>
                            <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
                        </tr>
                        </tfoot>
                        <tbody>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- Admins Modal-->
        <modal-component id="admin-modal" :title="modal.title" :button="modal.button" :action="modal.action">
            <form id="admin-form" autocomplete="off">
                <div class="row">
                    <div class="col-6">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-comments"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Όνομα Διαχειριστή" id="input-name"
                                   name="input-name"
                                   v-model="inputs.name">
                        </div>

                    </div>
                    <div class="col-6">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-comments"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="Επώνυμο Διαχειριστή" id="input-surname"
                                   name="input-surname" v-model="inputs.surname">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-at"></i></span>
                            </div>
                            <input type="email" class="form-control" placeholder="Email Διαχειριστή" id="input-email"
                                   name="input-email"
                                   v-model="inputs.email" autocomplete="off" :readonly="inputs.id > 0">
                        </div>
                    </div>
                </div>
                <div class="row" v-if="inputs.id === 0">
                    <div class="col-6">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-lock"></i></span>
                            </div>
                            <input type="password" class="form-control" placeholder="Κωδικός Διαχειριστή"
                                   id="input-password" name="input-password"
                                   v-model="inputs.password" autocomplete="new-password">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fas fa-lock"></i></span>
                            </div>
                            <input type="password" class="form-control" placeholder="Επιβεβαίωση Κωδικού"
                                   id="input-c-password" name="input-c-password"
                                   v-model="inputs.c_password" autocomplete="new-password">
                        </div>
                    </div>
                </div>
            </form>
        </modal-component>
    </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import guard from "@/library/check-token";
import $ from "jquery";
import datatableLanguages from "@/library/DatatableLanguages";
import catchDatatableErrors from "@/library/error-handling/Datatable";
import catchCrudErrors from "@/library/ErrorHandling";
import ModalComponent from "@/components/ModalComponent.vue";
import {destroyElement, editElement, storeElement, updateElement} from "@/library/CRUD";
import axios from "axios";

export default {
    name: "AdminsView",
    components: {AdminLayout, ModalComponent},
    beforeRouteEnter(to, from, next) {
        guard(to, from, next)
    },
    created() {
        window.prepare = this.prepare;
        window.edit = this.edit;
        window.destroy = this.destroy;
    },
    data() {
        return {
            page: {
                active: "admins",
                title: '<i class="fas fa-fw fa-user-shield mr-2"></i>Διαχειριστές',
                button: '<button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onclick="prepare()"><i class="fas fa-plus fa-sm text-white-50"></i> Προσθήκη Νέου Διαχειριστή</button>'
            },
            table: null,
            form: "#admin-form",
            inputs: {
                id: 0,
                name: "",
                surname: "",
                email: "",
                password: "",
                c_password: ""
            },
            modal: {
                title: null,
                button: null,
                action: null
            },
        }
    },
    mounted() {
        this.table = $("#admins-datatable").DataTable({
            columnDefs: [
                {orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
                {orderable: true, className: 'dt-center', targets: 1, width: 'auto'},
                {orderable: true, className: 'dt-center', targets: 2, width: 'auto'},
                {orderable: false, className: 'dt-center', targets: 3, width: 'auto'}
            ],
            language: datatableLanguages,
            autoWidth: false,
            processing: true,
            serverSide: true,
            ajax: {
                type: 'POST',
                url: `${process.env.VUE_APP_API_URL}/api/admins/data`,
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                error: (error) => catchDatatableErrors(error)
            },
        })
    },
    methods: {
        prepare() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/admins/prepare`, {
                headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
            }).then((response) => {
                this.modal = response.data
                this.modal.action = this.store;
                this.resetData();
                $("#admin-modal").modal("show");
            }).catch(
                error => catchCrudErrors(error.response)
            );

        },
        store() {
            let formData = new FormData();
            formData.append('name', this.inputs.name);
            formData.append('surname', this.inputs.surname);
            formData.append('email', this.inputs.email);
            formData.append('password', this.inputs.password);
            formData.append('c_password', this.inputs.c_password);

            storeElement(formData, `${process.env.VUE_APP_API_URL}/api/admins/store`, this.table, $("#admin-modal"))
        },
        edit(id) {
            let success = (response) => {
                this.inputs = response.data;
                this.modal = response.modal;
                this.modal.action = this.update;
                $("#admin-modal").modal("show");
            }
            editElement(id, `${process.env.VUE_APP_API_URL}/api/admins/edit`, success);
        },
        update() {
            let formData = new FormData();
            formData.append('name', this.inputs.name);
            formData.append('surname', this.inputs.surname);
            formData.append('email', this.inputs.email);
            formData.append('password', this.inputs.password);
            formData.append('c_password', this.inputs.c_password);
            formData.append('input-id', this.inputs.id);

            updateElement(formData, `${process.env.VUE_APP_API_URL}/api/admins/update`, this.table, $("#admin-modal"));
        },
        destroy(id) {
            destroyElement(id, `${process.env.VUE_APP_API_URL}/api/admins/destroy`, 'Αυτή η ενέργεια θα διαγράψει μόνιμα τον διαχειριστή!', this.table);
        },
        resetData() {
            this.inputs = {
                id: 0,
                name: "",
                surname: "",
                email: "",
                password: "",
                c_password: ""
            }
        }
    }
}
</script>

<style scoped>

</style>
<template>
  <!-- Page Wrapper -->
  <div id="wrapper">
    <side-bar-component :active="activeMenu"/>

    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">

      <!-- Main Content -->
      <div id="content">

        <top-bar-component/>

        <!-- Begin Page Content -->
        <div class="container-fluid">

          <page-heading-component v-if="pageTitle" :title="pageTitle" :button="pageButton"/>
          <div class="d-flex justify-content-center">
            <div class="loader-wrapper" v-if="showLoader">
              <div class="loader"></div>
            </div>
          </div>

          <slot/>


        </div>
        <!-- /.container-fluid -->

      </div>
      <!-- End of Main Content -->

      <footer-component/>

    </div>
    <!-- End of Content Wrapper -->
  </div>
  <!-- End of Page Wrapper -->

  <scroll-to-top-component/>

  <logout-modal-component/>
</template>

<script>
import SideBarComponent from "@/components/SideBarComponent";
import TopBarComponent from "@/components/TopBarComponent";
import PageHeadingComponent from "@/components/PageHeadingComponent";
import FooterComponent from "@/components/FooterComponent";
import ScrollToTopComponent from "@/components/ScrollToTopComponent";
import LogoutModalComponent from "@/components/LogoutModalComponent";

import "jquery/dist/jquery.min"
import "bootstrap/dist/js/bootstrap.bundle.min"

import "@/assets/vendor/jquery-easing/jquery.easing.min.js"

import $ from 'jquery'

export default {
  name: "AdminLayout",
  components: {
    SideBarComponent,
    TopBarComponent,
    PageHeadingComponent,
    FooterComponent,
    ScrollToTopComponent,
    LogoutModalComponent
  },
  data() {
    return {
      showLoader: false
    }
  },
  props: ['activeMenu', 'pageTitle', 'pageButton'],
  mounted() {

    document.body.classList.remove('bg-dark')
    const sidebar = $(".sidebar");

    // eslint-disable-next-line no-unused-vars
    $("#sidebarToggle, #sidebarToggleTop").on('click', function (e) {
      $("body").toggleClass("sidebar-toggled");
      sidebar.toggleClass("toggled");
      if (sidebar.hasClass("toggled")) {
        $('.sidebar .collapse').collapse('hide');
      }
    });

    // Close any open menu accordions when window is resized below 768px
    $(window).resize(function () {
      if ($(window).width() < 768) {
        $('.sidebar .collapse').collapse('hide');
      }

      // Toggle the side navigation when window is resized below 480px
      if ($(window).width() < 480 && !sidebar.hasClass("toggled")) {
        $("body").addClass("sidebar-toggled");
        sidebar.addClass("toggled");
        $('.sidebar .collapse').collapse('hide');
      }
    });

    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    $('body.fixed-nav .sidebar').on('mousewheel DOMMouseScroll wheel', function (e) {
      if ($(window).width() > 768) {
        const e0 = e.originalEvent,
            delta = e0.wheelDelta || -e0.detail;
        this.scrollTop += (delta < 0 ? 1 : -1) * 30;
        e.preventDefault();
      }
    });

    // Scroll to top button appear
    $(document).on('scroll', function () {
      const scrollDistance = $(this).scrollTop();
      if (scrollDistance > 100) {
        $('.scroll-to-top').fadeIn();
      } else {
        $('.scroll-to-top').fadeOut();
      }
    });

    // Smooth scrolling using jQuery easing
    $(document).on('click', 'a.scroll-to-top', function (e) {
      const $anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: ($($anchor.attr('href')).offset().top)
      }, 1000, 'easeInOutExpo');
      e.preventDefault();
    });
  }
}
</script>

<style scoped>
@import "@/assets/css/google.css";
@import "@/assets/vendor/fontawesome-free/css/all.min.css";
@import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "@/assets/css/sb-admin-2.css";

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.loader {
  border: 15px solid #f3f3f3;
  border-radius: 50%;
  border-top: 15px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
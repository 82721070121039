<template>
  <admin-layout :active-menu="page.active" :page-title="page.title" :page-button="page.button">
    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold text-primary">Πίνακας Διαχείρισης Πελατών</h6>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered" id="clients-datatable">
            <thead>
            <tr>
              <th><i class="fas fa-hashtag mr-2"></i>Κωδικός</th>
              <th><i class="fas fa-comments mr-2"></i>Ονοματεπώνυμο</th>
              <th><i class="fas fa-at mr-2"></i>Email</th>
              <th><i class="fas fa-building mr-2"></i>Εταιρεία</th>
              <th><i class="fas fa-globe mr-2"></i>Χώρα</th>
              <th><i class="fas fa-file-alt mr-2"></i>ΑΦΜ</th>
              <th><i class="fas fa-phone-alt mr-2"></i>Τηλέφωνο</th>
              <th><i class="fas fa-money-bill-wave mr-2"></i>Τζίρος</th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><i class="fas fa-cogs mr-2"></i>Ενέργειες</th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Clients Modal-->
    <modal-component id="client-modal" :title="modal.title" :button="modal.button" :action="modal.action">
      <form id="client-form">
        <div class="row">
          <div class="col-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-hashtag"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Κωδικός Πελάτη" id="input-code" name="input-code" v-model="inputs.code">
            </div>
          </div>
          <div class="col-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-comments"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Όνομα Πελάτη" id="input-name" name="input-name" v-model="inputs.name">
            </div>

          </div>
          <div class="col-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-comments"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Επώνυμο Πελάτη" id="input-surname" name="input-surname" v-model="inputs.surname">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-at"></i></span>
              </div>
              <input type="email" class="form-control" placeholder="Email Πελάτη" id="input-email" name="input-email" v-model="inputs.email">
            </div>
          </div>
          <div class="col-6">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-shopping-bag"></i></span>
              </div>
              <select class="form-control" id="input-type" name="input-type" v-model="inputs.type">
                <option value="0" selected disabled>Τύπος</option>
                <option value="1">Λιανική</option>
                <option value="2">Χονδρική</option>
              </select>
            </div>
          </div>
        </div>
        <hr>

        <div class="row">

          <div class="col-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-phone"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Τηλέφωνο" id="input-phone" name="input-phone" v-model="inputs.phone">
            </div>
          </div>

          <div class="col-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-map-marked"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Διεύθυνση" id="input-address" name="input-address" v-model="inputs.address">
            </div>
          </div>

          <div class="col-4">
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-map-pin"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Ταχ. Κώδικας" id="input-zip" name="input-zip" v-model="inputs.zip">
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-4">

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-map"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Περιοχή" id="input-area" name="input-area" v-model="inputs.area">
            </div>

          </div>
          <div class="col-4">

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-city"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Πόλη" id="input-city" name="input-city" v-model="inputs.city">
            </div>

          </div>
          <div class="col-4">

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-globe"></i></span>
              </div>
              <select class="form-control" id="input-country" name="input-country" v-model="inputs.country">
                <option value="0" selected disabled>Χώρα</option>
                <option v-for="country in countries" :key="country.id" :value="country.id" selected>
                    {{ country.name }}
                </option>
              </select>
            </div>

          </div>
        </div>
        <hr>

        <div class="row">
          <div class="col-6">
            <div class="form-group">

              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-briefcase"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Επάγγελμα" id="input-job" name="input-job" v-model="inputs.job">
              </div>

            </div>
          </div>
          <div class="col-6">

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-building"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="Εταιρεία" id="input-company" name="input-company" v-model="inputs.company">
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-6">

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-file-alt"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="ΑΦΜ" id="input-afm" name="input-afm" v-model="inputs.afm">
            </div>

          </div>
          <div class="col-6">

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fas fa-business-time"></i></span>
              </div>
              <input type="text" class="form-control" placeholder="ΔΟΥ" id="input-doy" name="input-doy" v-model="inputs.doy">
            </div>

          </div>
        </div>
      </form>
    </modal-component>

  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";
import ModalComponent from "@/components/ModalComponent.vue";

import $ from 'jquery'

import catchDatatableErrors from "@/library/error-handling/Datatable";
import catchCrudErrors from "@/library/ErrorHandling";
import datatableLanguages from "@/library/DatatableLanguages";

import 'datatables/media/js/jquery.dataTables.min'
import 'datatables.net-bs4/js/dataTables.bootstrap4.min'
import guard from "@/library/check-token";
import {destroyElement, editElement, prepareElement, storeElement, updateElement} from "@/library/CRUD";
import axios from "axios";

export default {
  name: "ClientsView",
  components: {AdminLayout, ModalComponent},
  beforeRouteEnter(to, from, next) {
    guard(to, from, next)
  },
  created() {
    window.prepare = this.prepare;
    window.edit = this.edit;
    window.destroy = this.destroy;
  },
  data() {

    return {
      page: {
        active: 'clients',
        title: '<i class="fas fa-fw fa-users mr-2"></i>Πελάτες',
        button: '<button type="button" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onclick="prepare()"><i class="fas fa-plus fa-sm text-white-50"></i> Προσθήκη Νέου Πελάτη</button>'
      },
      table: null,
      form: '#client-form',
      inputs: {
        id: 0,
        code: '',
        name: '',
        surname: '',
        email: '',
        type: 0,

        phone: '',
        address: '',
        area: '',
        city: '',
        country: 0,
        zip: '',

        job: '',
        company: '',
        afm: '',
        doy: ''
      },
      modal: {
        title: null,
        button: null,
        action: null
      },
      countries: {},
    }
  },

  mounted() {

    axios.get(`${process.env.VUE_APP_API_URL}/api/countries/getActive`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
    }).then(
        response => this.countries = response.data
    ).catch(
        error => catchCrudErrors(error.response)
    );

    this.table = $("#clients-datatable").DataTable({
      initComplete: function () {
        this.api()
            .columns()
            .every(function () {
              const that = this;

              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this.value) {
                  that.search(this.value).draw();
                }
              });
            });
      },
      columnDefs: [
        {data: 'code',  orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
        {data: 'full_name',  orderable: true, className: 'dt-center', targets: 1, width: 'auto'},
        {data: 'email',  orderable: true, className: 'dt-center', targets: 2, width: 'auto'},
        {data: 'company',  orderable: true, className: 'dt-center', targets: 3, width: 'auto'},
        {data: 'country',  orderable: true, className: 'dt-center', targets: 4, width: 'auto'},
        {data: 'afm',  orderable: true, className: 'dt-center', targets: 5, width: 'auto'},
        {data: 'phone',  orderable: true, className: 'dt-center', targets: 6, width: 'auto'},
        {data: 'turnover',  orderable: true, className: 'dt-center', targets: 7, width: 'auto'},
        {data: null, render: (data) => this.getActionButtons(data.id), orderable: false, searchable: false, className: 'dt-center', targets: 8, width: 'auto'}
      ],
      language: datatableLanguages,
      autoWidth: false,
      processing: true,
      serverSide: true,
      ajax: {
        type: 'POST',
        url: `${process.env.VUE_APP_API_URL}/api/clients/data`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        error: (error) => catchDatatableErrors(error)
      },
    })
  },
  methods: {
    getActionButtons(id) {
      let editButton = `<button class="btn btn-outline-warning btn-sm mr-1" id="edit-client-${id}" onclick="edit(${id})"><i class="fas fa-edit"></i></button>`;
      let destroyButton = `<button class="btn btn-outline-danger btn-sm ml-1" id="destroy-client-${id}" onclick="destroy(${id})"><i class="fas fa-trash"></i></button>`;
      return editButton + destroyButton;
    },
    prepare() {
      let success = (response) => {
        this.modal = response
        this.modal.action = this.store;
        this.resetData();
        $("#client-modal").modal("show");
      }
      prepareElement(`${process.env.VUE_APP_API_URL}/api/clients/prepare`, success);
    },
    store() {
      // Serialize the form
      $(this.form).serialize();
      let form = $(this.form);
      let formData = new FormData(form[0])

      storeElement(formData, `${process.env.VUE_APP_API_URL}/api/clients/store`, this.table, $("#client-modal"))
    },
    edit(id) {
      let success = (response) => {
        this.inputs = response.data;
        this.modal = response.modal;
        this.modal.action = this.update;
        $("#client-modal").modal("show");
      }
      editElement(id, `${process.env.VUE_APP_API_URL}/api/clients/edit`, success);
    },
    update() {
      // Serialize the form
      $(this.form).serialize();
      let form = $(this.form);
      let formData = new FormData(form[0])

      // Append the ID to the form data object
      formData.append('input-id', this.inputs.id);

      updateElement(formData, `${process.env.VUE_APP_API_URL}/api/clients/update`, this.table, $("#client-modal"));
    },
    destroy(id) {
      destroyElement(id, `${process.env.VUE_APP_API_URL}/api/clients/destroy`, 'Αυτή η ενέργεια θα διαγράψει μόνιμα τον πελάτη!', this.table);
    },
    resetData() {
      this.inputs = {
        id: 0,
        code: '',
        name: '',
        surname: '',
        email: '',
        type: 0,

        phone: '',
        address: '',
        area: '',
        city: '',
        country: 0,
        zip: '',

        job: '',
        company: '',
        afm: '',
        doy: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
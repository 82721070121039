const datatableLanguages = {
    lengthMenu: "Προβολή _MENU_ εγγραφών ανά σελίδα",
    zeroRecords: "Δεν βρέθηκαν εγγραφές!",
    info: "Προβολή σελίδας _PAGE_ από _PAGES_ σελίδες συνολικά",
    infoEmpty: "Δεν βρέθηκαν εγγραφές!",
    infoFiltered: "(μέσα από έρευνα σε _MAX_ συνολικά εγγραφές)",
    decimal: ",",
    thousands: ".",
    search: "Αναζήτηση",
    loadingRecords: "Φόρτωση δεδομένων...",
    processing: "Επεξεργασία...",
    paginate: {
        first: "&laquo;",
        last: "&raquo;",
        next: "&rsaquo;",
        previous: "&lsaquo;",
    },
};

export default datatableLanguages;
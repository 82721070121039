<template>
  <AuthLayout title="Αίτημα αλλαγής κωδικού πρόσβασης">
    <form>
      <div class="form-group">
        <label for="input-email">Διεύθυνση Email</label>
        <input class="form-control" id="input-email" type="email" placeholder="Εισάγετε το email σας" v-model="email" @keyup.enter="forgotPassword">
      </div>
      <button type="button" class="btn btn-primary btn-block" @click="forgotPassword">Αποστολή Αιτήματος</button>
    </form>
    <div class="text-center">
      <router-link :to="{name: 'login'}" class="d-block small mt-3">Επιστροφή στην οθόνη σύνδεσης</router-link>
    </div>
  </AuthLayout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout";
import $ from "jquery";

export default {
  name: "ForgotPasswordView",
  components: { AuthLayout },
  data() {
    return {
      email: ''
    }
  },
  methods: {
    forgotPassword() {
      // Ajax Request to the REST API
      $.ajax({
        type: 'POST',
        url: `${process.env.VUE_APP_API_URL}/api/forgot-password`,
        data: {"email": this.email},
        dataType: 'JSON',
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        success: (response) => {
          localStorage.setItem('email', this.email);
          this.$toast.success(response.message)
        },
        error: (error) => {
          if (error.status === 422) {
            this.$toast.error(error.responseJSON.errors[Object.keys(error.responseJSON.errors)[0]][0])
          } else if (error.status === 400) {
            this.$toast.error('Περιμένετε λίγο πρωτού ξαναπροσπαθήσετε!')
          } else if (error.status === 429) {
            this.$toast.error('Υπερβολικά πολλά αιτήματα! Δοκιμάστε ξανά μετά από λίγα λεπτά')
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <admin-layout :active-menu="page.active" :page-title="page.title">

    <div class="card shadow mb-4">
      <div class="card-header py-3">
        <div class="row">
          <div class="col-6">
            <h6 class="m-0 font-weight-bold text-primary">Πίνακας Διαχείρισης Χωρών</h6>
          </div>
          <div class="col-6">
            <div class="custom-control custom-switch float-right">
              <input type="checkbox" id="countries-toggle-activities" class="custom-control-input"
                     onclick="toggleActivities()" style="cursor: pointer">
              <label class="custom-control-label" for="countries-toggle-activities">Ενεργοποίηση Όλων</label>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered" id="countries-datatable">
            <thead>
            <tr>
              <th><i class="fas fa-quote-left mr-2"></i>Συντομογραφία</th>
              <th><i class="fas fa-comments mr-2"></i>Όνομα</th>
              <th><i class="fas fa-eye mr-2"></i>Ενεργή</th>
            </tr>
            </thead>
            <tfoot>
            <tr>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><input class="form-control" type="text" placeholder="Αναζήτηση..."></th>
              <th><i class="fas fa-eye mr-2"></i>Ενεργή</th>
            </tr>
            </tfoot>
            <tbody>

            </tbody>
          </table>
        </div>
      </div>
    </div>

  </admin-layout>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout.vue";

import $ from 'jquery'
import 'datatables/media/js/jquery.dataTables.min'
import 'datatables.net-bs4/js/dataTables.bootstrap4.min'

import catchDatatableErrors from "@/library/error-handling/Datatable";
import datatableLanguages from "@/library/DatatableLanguages";
import guard from "@/library/check-token";
import catchCrudErrors from "@/library/ErrorHandling";
import axios from "axios";

export default {
  name: "CountriesView",
  components: {AdminLayout},
  beforeRouteEnter(to, from, next) {
    guard(to, from, next)
  },
  data() {
    return {
      page: {
        active: "countries",
        title: '<i class="fas fa-fw fa-globe mr-2"></i>Χώρες'
      },
      table: null,
      loader: $('.loader-wrapper'),
    }
  },
  created() {
    window.toggleActivity = this.toggleActivity;
    window.toggleActivities = this.toggleActivities;
  },
  mounted() {
    this.table = $("#countries-datatable").DataTable({
      initComplete: function () {
        this.api()
            .columns()
            .every(function () {
              const that = this;

              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this.value) {
                  that.search(this.value).draw();
                }
              });
            });
      },
      columnDefs: [
        {data: 'code', name: 'code', orderable: true, className: 'dt-center', targets: 0, width: 'auto'},
        {data: 'name', name: 'name', orderable: true, className: 'dt-center', targets: 1, width: 'auto'},
        {
          data: null,
          name: 'active',
          render: (data) => this.getActionButtons(data.id, data.active),
          orderable: true,
          searchable: false,
          className: 'dt-center',
          targets: 2,
          width: 'auto'
        }
      ],
      order: [[2, 'desc']],
      language: datatableLanguages,
      autoWidth: false,
      processing: true,
      serverSide: true,
      ajax: {
        type: 'POST',
        url: `${process.env.VUE_APP_API_URL}/api/countries/data`,
        headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        error: (error) => catchDatatableErrors(error)
      },
    })
  },
  methods: {
    getActionButtons(id, active) {
      let toggleActivityButton = '<div class="custom-control custom-switch">';
      toggleActivityButton += `<input type="checkbox" id="countries-toggle-activity-${id}" class="custom-control-input" ${active ? 'checked' : ''} onclick="toggleActivity(${id})" style="cursor: pointer">`;
      toggleActivityButton += `<label class="custom-control-label" for="countries-toggle-activity-${id}"></label>`;
      toggleActivityButton += '</div>';
      return toggleActivityButton;
    },
    toggleActivity(id) {
        this.loader.show()
        axios.patch(`${process.env.VUE_APP_API_URL}/api/countries/toggle`, {id: id}, {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
        }).then((response) => {
            this.$toast.success(response.data.message)
            this.table.ajax.reload(null, false);
        }).catch((error) => {
            catchCrudErrors(error.response);
            this.table.ajax.reload(null, false);
        })
        this.loader.hide();
    },
    toggleActivities() {
        this.loader.show()
        axios.patch(`${process.env.VUE_APP_API_URL}/api/countries/toggleAll`, {
            checked: $("#countries-toggle-activities").prop('checked')
        }, {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`}
        }).then((response) => {
            this.$toast.success(response.data.message)
            this.table.ajax.reload(null, false);
        }).catch((error) => {
            catchCrudErrors(error.response);
            this.table.ajax.reload(null, false);
        })
        this.loader.hide();
    }
  }
}
</script>

<style scoped>

</style>